import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import moment from 'moment';
import * as plannerOps from '../../operations/schedule';
import { getVisibleEvents } from 'reducers/calendar';
import Analytics from '../../analytics.jsx';
import { Button, Grid } from '@mui/material';
import { PremiumModalDock } from '../../premium';
import { usePredictDuration } from '../../operations/ml';
import * as actions from 'actions';
import { theme } from '../../theme';
import {
  SuggestionEvent,
  Task,
  enhanceTaskWithUserScheduleConfig,
  formatDate,
  formatHumanDate,
  generateDatesRange,
  generateSuggestionsForTasks,
  isRecurringTask,
} from 'shared';
import { ReduxState } from 'reducers';
import { useCancellableEffect } from 'common/useCancellableEffect';
import { Date_max } from 'common/utils';
import { getCachedTaskPredictions } from 'common/firebase';

const MinAllDuration = 24 * 60;
const MAX_PERIOD_DAYS = 7;
const MAX_NUM_SUGGESTIONS = 4;

// On Hover of Schedule Widget we set ShowSuggestionsForTaskId to TaskId
// This should trigger dispatch(actions.setSuggestionEvents(suggestionEvents)); but limit results to 1 event
// On LongPress of Schedule Widget we should call onSchedule() with the first suggestion event
// On click of the Schedule Widget we should render 2-4 buttons and display 2-4 suggestion events

// TODO:
// Hover and Click should dispatch to set showSuggestionForTaskId
// if showSuggestionForTaskId is present, we render SmartSuggestions

interface GenerateSuggestionEventsProps {
  task: Task;
  limit: number;
  startDate: string;
  endDate: string;
}

const emptySuggestionsArray: SuggestionEvent[] = [];

const useGenerateSuggestionEvents = ({
  task,
  limit = MAX_NUM_SUGGESTIONS,
  startDate,
  endDate,
}: GenerateSuggestionEventsProps) => {
  // const rawDatesRange = useSelector((state: ReduxState) => state.ui.viewDatesRange);
  const prefs = useSelector((state: ReduxState) => state.account?.user?.prefs);
  const events = useSelector(getVisibleEvents);

  const [suggestionEvents, setSuggestionEvents] = useState<SuggestionEvent[]>([]);

  const listsObj = useSelector<ReduxState, ReduxState['tasks']['listsObj']>(
    (state: ReduxState) => state.tasks.listsObj
  );
  const listColor = task ? listsObj[task.listId]?.color : '';

  const taskJSON = JSON.stringify(task);
  const shallowEventsJSON = JSON.stringify(
    events?.map(({ allDay, beginDate, endDate, isFree = false }) => ({ allDay, beginDate, endDate, isFree }))
  );

  const { topPrediction, predictDurationEnabled, loading: _predictionIsLoading } = usePredictDuration(task);
  const predictionIsLoading = _predictionIsLoading || (predictDurationEnabled && !topPrediction);

  const eventDuration = task.eventId ? moment(task.eventEndDate).diff(task.eventBeginDate, 'minutes') : null;
  const defaultTaskDuration = prefs?.default_task_duration || 60;

  let duration = eventDuration || task.duration || topPrediction || defaultTaskDuration;
  let allDay = false;
  let originalDuration; // used to calculate difference in start and end for all-day events
  if (duration >= MinAllDuration) {
    originalDuration = duration;
    duration = defaultTaskDuration;
    allDay = true;
  }

  useCancellableEffect(
    async (cancelHolder) => {
      const task = JSON.parse(taskJSON) as Task;
      if (task.completed) return;

      const shallowEvents = JSON.parse(shallowEventsJSON);

      const timeNow = moment();
      // const start = moment(rawDatesRange.start);
      const start = moment(startDate);

      let fromDate = Date_max(timeNow, start);
      let toDate = moment(fromDate).add(MAX_PERIOD_DAYS, 'days');

      if (task.dueDate && timeNow.isSameOrBefore(task.dueDate, 'date')) {
        toDate = moment(task.dueDate).endOf('day');
        fromDate = moment(task.dueDate).startOf('day');
        if (!(isRecurringTask(task) ? prefs.recurring_due_date_is_exact_date : prefs.due_date_is_exact_date)) {
          fromDate.subtract(MAX_PERIOD_DAYS, 'days');
        }
        fromDate = Date_max(timeNow, fromDate);
      }

      const ctx = {
        momenttz: moment,
        prefs,
      };

      let dates: moment.Moment[] = generateDatesRange(ctx, fromDate, toDate);

      console.log('SmartSuggestions generateSuggestionsForTasks:', shallowEvents);

      const suggestionsWithScore = [];
      for (const date of dates) {
        if (cancelHolder.isCancelled) return;

        const daySuggestions = await generateSuggestionsForTasks({
          ctx,
          tasks: [enhanceTaskWithUserScheduleConfig(task, prefs.user_schedule, listsObj)],
          fromDate: date,
          toDate: date,
          events: shallowEvents,
          strategy: 'predicted',
          limit: 1,
          hintDatetime: task.eventBeginDate,
          getCachedTaskPredictions,
        });
        suggestionsWithScore.push(...daySuggestions);
      }

      const suggestions = suggestionsWithScore
        .sort((a, b) => b.score - a.score)
        .slice(0, limit)
        .map(({ beginDate }) => beginDate)
        .sort((a, b) => a.diff(b, 'minutes'));

      const suggestionEvents = suggestions.map((suggestion) => {
        let start = allDay ? moment(suggestion).format('YYYY-MM-DD') : moment.utc(suggestion).format();
        let end = allDay
          ? moment(suggestion).add(originalDuration, 'm').format('YYYY-MM-DD')
          : moment(suggestion).add(duration, 'm').utc().format();
        return {
          title: task.title,
          start,
          end,
          allDay,
          className: 'suggestion-event',
          editable: false,
          suggestionTask: task,
          backgroundColor: listColor,
          borderColor: listColor,
          textColor: listColor,
        };
      });

      if (cancelHolder.isCancelled) return;
      setSuggestionEvents(suggestionEvents);
    },
    [startDate, endDate, allDay, prefs, shallowEventsJSON, duration, originalDuration, taskJSON, listColor]
    // 1200
  );
  return {
    suggestionEvents: predictionIsLoading ? emptySuggestionsArray : suggestionEvents,
  };
};

const onSchedule = (task: Task, beginDate, endDate, allDay) => {
  const hint = {
    beginDate,
    endDate,
    allDay,
  };
  plannerOps.scheduleTask(task, hint);
  Analytics.event({ category: 'Scheduling', action: 'Scheduled Task from Smart Suggestions' });
};

interface SmartSuggestionsButtonsProps {
  task: Task;
}

export const SmartSuggestionsButtons: React.FC<SmartSuggestionsButtonsProps> = ({ task }) => {
  const suggestionEvents = useSelector<ReduxState, SuggestionEvent[]>((state) => state.calendar.suggestionEvents);
  if (!suggestionEvents || suggestionEvents.length === 0) return null;

  return (
    <Grid
      container
      spacing={0.5}
      justifyContent="space-around"
      sx={{
        padding: '4px 0 0 4px',
        alignItems: 'stretch',
        alignContent: 'stretch',
        justifyContent: 'flex-start',
        fontSize: '14px',
      }}
      className="task-smart-suggestions-bar"
    >
      {suggestionEvents.map(({ start: date, end, allDay }) => {
        const title = formatHumanDate(date, allDay);
        return (
          <Grid item xs={12} lg={suggestionEvents.length > 1 ? 6 : 12} key={date}>
            <Button
              variant="outlined"
              sx={{
                width: '100%',
                height: '100%',
                fontSize: '13px',
              }}
              onClick={() => onSchedule(task, date, end, allDay)}
            >
              {title}
            </Button>
          </Grid>
        );
      })}
    </Grid>
  );
};

export const SmartSuggestions: FC<{ task: Task; limit?: number; isHovered?: boolean }> = React.memo(
  ({ task, limit = 4, isHovered = false }) => {
    const dispatch = useDispatch();
    console.log('Task Planner - Smart Suggestions received task: ', task);

    const rawDatesRange = useSelector((state: ReduxState) => state.ui.viewDatesRange);
    const refOriginalDatesRange = useRef(rawDatesRange);

    useEffect(() => {
      // it's done to ensure that the events there are up to date
      // TODO - do this with one time thing without watchers and stuff and not causing to much redux noise
      if (task.dueDate && moment(task.dueDate).diff(moment(), 'days') > 0) {
        const newRangeStart = moment(task.dueDate).subtract(task.notEarlierThanDays ?? 7, 'days');
        dispatch(actions.changeCalendarDateRange(newRangeStart, task.dueDate, false));

        const dateRange = refOriginalDatesRange.current;

        return () => {
          if (dateRange)
            dispatch(actions.changeCalendarDateRange(moment(dateRange.start), moment(dateRange.end), false));
        };
      }
    }, [task.dueDate, task.notEarlierThanDays, dispatch]);

    const startDate = formatDate(
      task.dueDate
        ? moment(task.dueDate)
            .subtract(task.notEarlierThanDays ?? 7, 'days')
            .format()
        : rawDatesRange.start
    );
    const endDate = formatDate(task.dueDate ? task.dueDate : rawDatesRange.end);
    const { suggestionEvents } = useGenerateSuggestionEvents({ task, limit, startDate, endDate });
    console.log('Task Planner - Smart Suggestions | suggestionEvents prop: ', suggestionEvents);

    const suggestionEventsSlice = useMemo(() => {
      if (isHovered) {
        return suggestionEvents.slice(0, 1).map((s) => ({ ...s, scrollIntoView: true }));
      }
      return suggestionEvents;
    }, [suggestionEvents, isHovered]);

    useEffect(() => {
      if (!suggestionEventsSlice?.length) return;

      dispatch(actions.setSuggestionEvents(suggestionEventsSlice));

      var restoreRange: any = null;
      if (
        suggestionEventsSlice.length === 1 &&
        (moment(refOriginalDatesRange.current.end).isBefore(suggestionEventsSlice[0].start) ||
          moment(refOriginalDatesRange.current.start).isAfter(suggestionEventsSlice[0].start))
      ) {
        restoreRange = refOriginalDatesRange.current;
        const datesRangeLength = moment(refOriginalDatesRange.current.end).diff(
          refOriginalDatesRange.current.start,
          'days'
        );
        const newDateRangeStart = moment(suggestionEventsSlice[0].start);

        dispatch(
          actions.changeCalendarDateRange(newDateRangeStart, newDateRangeStart.clone().add(datesRangeLength, 'days'))
        );

        (window as any).calendar.gotoDate(moment(suggestionEventsSlice[0].start).toDate());
      }

      return () => {
        dispatch(actions.compareAndClearSuggestionEvents(suggestionEventsSlice));

        if (restoreRange) {
          setTimeout(() => {
            // for a nicer effect - see that the event is added.
            // perhaps we should consider not changing it at all in case of long-press schedule
            (window as any).calendar.gotoDate(moment(restoreRange.start).toDate());
          }, 500);

          dispatch(actions.changeCalendarDateRange(restoreRange.start, restoreRange.end));
        }
      };
    }, [suggestionEventsSlice, dispatch]);

    return null;
  }
);

// OLD CODE FOR REFERENCE

// export const SmartSuggestions: FC<{ task: Task; limit?: number; renderButtons?: boolean }> = React.memo(
//   ({ task, limit = MAX_NUM_SUGGESTIONS || 3, renderButtons = true }) => {
//     const { rawDatesRange, prefs, events, freeLimitReached } = useSelector((state: ReduxState) => {
//       // const { isPremium, freeEventsLeft } = state.account.user;
//       // const freeLimitReached = !isPremium && !freeEventsLeft;
//       const freeLimitReached = false; // 'schedule' upgrade module not currently in use

//       return {
//         rawDatesRange: state.ui.viewDatesRange,
//         prefs: state.account?.user?.prefs,
//         events: getVisibleEvents(state),
//         freeLimitReached,
//       };
//     }, shallowEqual);
//     const dispatch = useDispatch();

//     const listsObj = useSelector<ReduxState, ReduxState['tasks']['listsObj']>(
//       (state: ReduxState) => state.tasks.listsObj
//     );
//     const listColor = task ? listsObj[task.listId]?.color : '';

//     const taskJSON = JSON.stringify(task);
//     const shallowEventsJSON = JSON.stringify(
//       events?.map(({ allDay, beginDate, endDate, isFree = false }) => ({ allDay, beginDate, endDate, isFree }))
//     );

//     const { topPrediction, predictDurationEnabled, loading: _predictionIsLoading } = usePredictDuration(task);
//     const predictionIsLoading = _predictionIsLoading || (predictDurationEnabled && !topPrediction);

//     const eventDuration = task.eventId ? moment(task.eventEndDate).diff(task.eventBeginDate, 'minutes') : null;
//     const defaultTaskDuration = prefs?.default_task_duration || 60;

//     let duration = eventDuration || task.duration || topPrediction || defaultTaskDuration;
//     let allDay = false;
//     let originalDuration; // used to calculate difference in start and end for all-day events
//     if (duration >= MinAllDuration) {
//       originalDuration = duration;
//       duration = defaultTaskDuration;
//       allDay = true;
//     }

//     const onSchedule = (beginDate, endDate, allDay) => {
//       if (freeLimitReached && !task.eventId) {
//         PremiumModalDock.showUpgradeModule('schedule');
//         Analytics.event({
//           category: 'Premium',
//           action: 'Free events limit reached',
//         });
//         return;
//       }
//       const hint = {
//         beginDate,
//         endDate,
//         allDay,
//       };
//       plannerOps.scheduleTask(task, hint);
//       Analytics.event({ category: 'Scheduling', action: 'Scheduled Task from Smart Suggestions' });
//     };

//     useEffect(() => {
//       if (task.dueDate && moment(task.dueDate).diff(rawDatesRange.end, 'days') > 0) {
//         dispatch(
//           actions.changeCalendarDateRange(moment(task.dueDate).subtract(MAX_PERIOD_DAYS, 'days'), task.dueDate, false)
//         );
//       }
//     }, [task.dueDate, rawDatesRange.end, dispatch]);

//     const [suggestionEvents, setSuggestionEvents] = useState([]);

//     useCancellableEffect(
//       async (cancelHolder) => {
//         const task = JSON.parse(taskJSON) as Task;
//         if (task.completed) return;

//         const shallowEvents = JSON.parse(shallowEventsJSON);

//         const timeNow = moment();
//         const start = moment(rawDatesRange.start);

//         let fromDate = Date_max(timeNow, start);
//         let toDate = moment(fromDate).add(MAX_PERIOD_DAYS, 'days');

//         if (task.dueDate && timeNow.isSameOrBefore(task.dueDate, 'date')) {
//           toDate = moment(task.dueDate).endOf('day');
//           fromDate = moment(task.dueDate).startOf('day');
//           if (!(isRecurringTask(task) ? prefs.recurring_due_date_is_exact_date : prefs.due_date_is_exact_date)) {
//             fromDate.subtract(MAX_PERIOD_DAYS, 'days');
//           }
//           fromDate = Date_max(timeNow, fromDate);
//         }

//         const ctx = {
//           momenttz: moment,
//           prefs,
//         };

//         let dates: moment.Moment[] = generateDatesRange(ctx, fromDate, toDate);

//         console.log('SmartSuggestions generateSuggestionsForTasks:', shallowEvents);

//         const suggestionsWithScore = [];
//         for (const date of dates) {
//           if (cancelHolder.isCancelled) return;

//           const daySuggestions = await generateSuggestionsForTasks({
//             ctx,
//             tasks: [enhanceTaskWithUserScheduleConfig(task, prefs.user_schedule, listsObj)],
//             fromDate: date,
//             toDate: date,
//             events: shallowEvents,
//             strategy: 'predicted',
//             limit: 1,
//             hintDatetime: task.eventBeginDate,
//             getCachedTaskPredictions,
//           });
//           suggestionsWithScore.push(...daySuggestions);
//         }

//         const suggestions = suggestionsWithScore
//           .sort((a, b) => b.score - a.score)
//           .slice(0, limit)
//           .map(({ beginDate }) => beginDate)
//           .sort((a, b) => a.diff(b, 'minutes'));

//         const suggestionEvents = suggestions.map((suggestion) => {
//           let start = allDay ? moment(suggestion).format('YYYY-MM-DD') : moment.utc(suggestion).format();
//           let end = allDay
//             ? moment(suggestion).add(originalDuration, 'm').format('YYYY-MM-DD')
//             : moment(suggestion).add(duration, 'm').utc().format();
//           return {
//             title: task.title,
//             start,
//             end,
//             allDay,
//             className: 'suggestion-event',
//             editable: false,
//             suggestionTask: task,
//             backgroundColor: listColor,
//             borderColor: listColor,
//             textColor: listColor,
//           };
//         });

//         if (cancelHolder.isCancelled) return;
//         setSuggestionEvents(suggestionEvents);
//       },
//       [rawDatesRange, allDay, prefs, shallowEventsJSON, duration, originalDuration, taskJSON, listColor]
//       // 1200
//     );

//     useEffect(() => {
//       if (predictionIsLoading) return;
//       dispatch(actions.setSuggestionEvents(suggestionEvents));

//       return () => {
//         dispatch(actions.compareAndClearSuggestionEvents(suggestionEvents));
//       };
//     }, [suggestionEvents, predictionIsLoading, dispatch]);

//     useEffect(() => {
//       if (!renderButtons) {
//         dispatch(actions.setSuggestionEvents(suggestionEvents));
//       }
//       return () => {
//         dispatch(actions.clearShowSuggestionsForTaskId(task.id));
//       };
//     }, [task.id, dispatch]);

//     if (!renderButtons) return null;

//     return (
//       <Grid
//         container
//         spacing={0.5}
//         justifyContent="space-around"
//         sx={{
//           padding: '4px 0 0 4px',
//           alignItems: 'stretch',
//           alignContent: 'stretch',
//           justifyContent: 'flex-start',
//           [theme.breakpoints.up('xs')]: {
//             //   maxHeight: '80px',
//             //   overflow: 'auto',
//             // padding: '0 8px 6px 12px',
//             fontSize: '14px',
//           },
//         }}
//         className="task-smart-suggestions-bar"
//       >
//         {suggestionEvents.map(({ start: date, end, allDay }) => {
//           const title = formatHumanDate(date, allDay);
//           return (
//             <Grid item xs={12} lg={suggestionEvents.length > 1 ? 6 : 12} key={date /*.unix()*/}>
//               <Button
//                 variant="outlined"
//                 sx={{
//                   width: '100%',
//                   height: '100%',
//                   fontSize: '13px',
//                 }}
//                 onClick={() => onSchedule(date, end, allDay)}
//               >
//                 {title}
//               </Button>
//             </Grid>
//           );
//         })}
//       </Grid>
//     );
//   }
// );

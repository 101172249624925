import { LoadingButton } from '@mui/lab';
import {
  Button,
  Paper,
  styled,
  Fab,
  Box,
  Tooltip,
  Badge,
  CircularProgress,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  FormHelperText,
  Collapse,
} from '@mui/material';
import { bulkCreateEventsFromSuggestions, bulkRemoveEvents, bulkUpdateEvents } from 'api/calendar';
import { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReduxState } from 'reducers';
import * as actions from 'actions';
import { SchedulingSuggestionsIcon } from 'components/CustomIcons.jsx';
import {
  AutoAwesome,
  Check,
  Close,
  EventAvailable,
  Replay,
  Undo,
  ExpandMore,
  ExpandLess,
  TaskOutlined,
  EventBusy,
} from '@mui/icons-material';
import { theme } from 'theme';
import Analytics from '../../analytics.jsx';
import {
  getAutoSuggestSourceFromLocalStorage,
  // saveAutoSuggestModeToLocalStorage,
  saveAutoSuggestSourceToLocalStorage,
} from 'reducers/calendar';
import {
  desktopScreen,
  generateEventId,
  isOverdueSuggestionsSnoozed,
  localStorageGetItem,
  localStorageSetItem,
  logError,
  mobileScreen,
} from 'common/utils';
import { Notification } from 'components';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { reduceTasksWithLLM } from 'api';
import { getOverdueTasks, getShortlistedTasks, getTasksForScheduleQueue } from 'reducers/tasks';
import { AutoSuggestSource } from 'shared';
import { useUserPrefs } from 'common/useUserPrefs';

interface FabulousBadgeProps extends React.PropsWithChildren<any> {
  textColor?: any;
}

const BottomFloatingPanel = styled(Paper)(({ theme }) => ({
  zIndex: 100,
  position: 'fixed',
  bottom: '0',
  left: '0',
  right: '0',
  margin: '10px auto',
  // width: '100%',
  maxWidth: '350px',
  padding: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '10px',
  borderRadius: theme.mainBox.radiusInt + 'px',
  boxShadow: theme.shadow.box,
}));

const FabulousFab = styled(Fab)(({ theme }) => ({
  height: '40px',
  width: '40px',
  background: 'transparent',
  boxShadow: 'none',
}));

const FabulousBadge = ({ textColor = 'inherit', ...props }: FabulousBadgeProps) => {
  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: 'transparent',
      color: textColor,
      top: '-4px !important',
      right: '-4px !important',
    },
  }));

  return <StyledBadge {...props} />;
};

const performBulkCreateEventsFromSuggestions = async ({ suggestionEvents }) => {
  const suggestions = suggestionEvents.map(({ suggestion }) => ({
    suggestId: generateEventId(),
    taskId: suggestion.task.id,
    title: suggestion.task.title,
    beginDate: suggestion.beginDate,
    duration: suggestion.duration,
  }));
  const res = await bulkCreateEventsFromSuggestions({ suggestions });
  console.log('Bulk created events result:', res);
  Analytics.event({ category: 'Scheduling', action: 'Scheduled Suggestions in Bulk' }); // Key Metric
  return { undo: () => bulkRemoveEvents({ eventIds: res.ids }) };
};

const performOverdueReschedule = async ({ suggestionEvents }) => {
  const eventData = suggestionEvents.map((ev) => {
    const { overdueTask: task, title, start, end, allDay } = ev;

    return {
      id: task.nextInstEventId || task.eventId,
      recurringEventMatchingTaskId: task.nextInstEventId ? task.id : null,
      title,
      beginDate: start,
      endDate: end,
      allDay,
    };
  });

  const undoEvents = suggestionEvents.map((ev) => {
    const { overdueTask: task, title, allDay } = ev;

    return {
      id: task.nextInstEventId || task.eventId,
      recurringEventMatchingTaskId: task.nextInstEventId ? task.id : null,
      title,
      beginDate: task.eventBeginDate,
      endDate: task.eventEndDate,
      allDay,
    };
  });

  const returnValue = { undo: () => bulkUpdateEvents({ events: undoEvents }) };
  try {
    const res = await bulkUpdateEvents({ events: eventData });
    console.log('Bulk updated events result:', res);
    Analytics.event({ category: 'Scheduling', action: 'Rescheduled Overdue Tasks in Bulk' }); // Key Metric
    return returnValue;
  } catch (err) {
    console.log('Bulk update events error:', err);
    console.log('Try to rollback...');
    try {
      await returnValue.undo();
      console.log('Rollback success');
    } catch (err) {
      console.log('Rollback error:', err);
    }
    throw err;
  }
};

const PillSelector: FC<{
  options: { value: string; title: string; description: string; disabled?: boolean }[];
  value: string;
  onChange: (val: string) => void;
  disabled?: boolean;
  fullWidth?: boolean;
  helperText?: string;
  styles?: any;
}> = ({ options, value, disabled, onChange, fullWidth, helperText, styles = {} }) => {
  return (
    <FormControl
      variant="outlined"
      sx={{
        // height: '38px',
        padding: '1px',
        borderRadius: '20px',
        width: fullWidth ? '100%' : 'auto',
        boxSizing: 'border-box',
      }}
    >
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
      <Select
        sx={{
          height: 'inherit',
          borderRadius: 'inherit',
          border: (theme: any) => '1px solid rgba(0,0,0,0.15)',
          background: 'none',
          width: '100%',
          '& .MuiSelect-select': { padding: '8px 30px 8px 16px !important' },
          '& fieldset': { border: 'none !important' },
          ...styles,
        }}
        disabled={disabled}
        value={value}
        labelId="task-selector-label"
        id="task-selector"
        label="Select Task"
        onChange={(e) => onChange(e.target.value as string)}
        renderValue={(selected) => {
          const selectedOption = options.find((option) => option.value === selected);
          return selectedOption ? selectedOption.title : '';
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
            <ListItemText primary={option.title} secondary={option.description} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const ScopeSelector = () => {
  const calRange = useSelector<ReduxState, ReduxState['ui']['calRange']>((state) => state.ui.calRange);

  const dispatch = useDispatch();

  const scopeSelectorOptions = [
    { value: 'daily', title: 'Day', description: `Plan for the day in view` },
    { value: 'threeDay', title: '3 Days', description: `Plan for the 3 days in view` },
    { value: 'weekly', title: 'Week', description: `Plan for the week in view` },
  ];

  const handleScopeSelectorChange = (newScopeValue: string) => {
    const selectedOption = scopeSelectorOptions.find((option) => option.value === newScopeValue);
    if (selectedOption) {
      dispatch(actions.changeCalendarRange(selectedOption.value));
      (window as any).calendar.changeView(selectedOption.value);
      localStorageSetItem('calendarRange', selectedOption.value);
      Analytics.event({
        category: 'Schedule',
        action: 'Changed Range to ' + selectedOption.value + ' from Plan My Day',
      });
      // setScopeSelector(newScopeValue);
    }
  };

  return (
    <PillSelector
      options={scopeSelectorOptions}
      // value={scopeSelector}
      value={calRange}
      onChange={handleScopeSelectorChange}
      fullWidth
      helperText="Select scheduling scope"
    />
  );
};

let defaultAutoSuggestSource = getAutoSuggestSourceFromLocalStorage() ?? 'queue';

export const useAutoSuggest = (): {
  autoSuggestSource: AutoSuggestSource;
  canSwitchSource?: boolean;
  autoGenerated?: boolean;
  isSchedueQueueDisabled?: boolean;
  isOverdueDisabled?: boolean;
  isListDisabled?: boolean;
} => {
  const selectedListId = useSelector<ReduxState, string>((state) => state.ui.selectedListId);
  const isQueueSelected = selectedListId === '__schedule_queue__';

  const tasksNotLoaded = useSelector<ReduxState, boolean>((state) => !Object.keys(state.tasks.listsObj).length);
  const scheduleQueueTasks = useSelector(getTasksForScheduleQueue);
  const overdueTasks = useSelector(getOverdueTasks);
  const showOverdueOnly = useSelector<ReduxState, boolean>((state) => state.ui.filter.showOverdueOnly);

  const reduxAutoSuggestSource = useSelector<ReduxState, AutoSuggestSource>(
    (state) => state.calendar.autoSuggestSource
  );

  const { overdue_suggestions_limit } = useUserPrefs();

  // to make sure this component is re-rendered when snoozeOverdueSuggestionsUntil changes
  useSelector<ReduxState, boolean>((state) => state.ui.snoozeOverdueSuggestionsUntil);
  const overdueSnoozedUntil = isOverdueSuggestionsSnoozed();

  const isSchedueQueueDisabled = !scheduleQueueTasks.length;
  const isOverdueDisabled = !overdueTasks.length;
  const isListDisabled = !selectedListId;

  console.log('useAutoSuggest:', {
    tasksNotLoaded,
    isQueueSelected,
    overdueSnoozedUntil,
    reduxAutoSuggestSource,
    scheduleQueueTasks,
    overdueTasks,
    showOverdueOnly,
  });

  if (tasksNotLoaded) return { autoSuggestSource: defaultAutoSuggestSource, canSwitchSource: false };

  const rest = {
    isSchedueQueueDisabled,
    isOverdueDisabled,
    isListDisabled,
  };

  if (isQueueSelected) {
    return { autoSuggestSource: 'queue', ...rest };
  } else if (selectedListId) {
    return { autoSuggestSource: 'list', ...rest };
  } else if (showOverdueOnly) {
    return { autoSuggestSource: 'overdue', ...rest };
  }

  const canSwitchSource = true;

  // explicitly selected
  if (reduxAutoSuggestSource) {
    if (reduxAutoSuggestSource === 'queue' && scheduleQueueTasks.length === 0) {
      // noop, use auto
    } else if (reduxAutoSuggestSource === 'overdue' && !overdueTasks.length) {
      // noop, use auto
    } else {
      // all good, use selected
      return {
        autoSuggestSource: reduxAutoSuggestSource,
        canSwitchSource,
        ...rest,
      };
    }
  }

  const autoGenerated = true;
  let autoSuggestSource: AutoSuggestSource = defaultAutoSuggestSource;

  if (overdueTasks.length && !overdueSnoozedUntil && !!overdue_suggestions_limit) {
    autoSuggestSource = 'overdue';
  } else if (!scheduleQueueTasks.length) {
    // fallback to Task Hub if Queue is empty
    autoSuggestSource = 'hub_llm';
  }

  return {
    autoSuggestSource,
    canSwitchSource,
    autoGenerated,
    ...rest,
  };
};

export const SuggestionsActionPanel: FC = () => {
  const router = useHistory();
  const {
    suggestionEvents,
    canAcceptAllSuggestions = false,
    autoSuggestMode,
    overdueMode,
  } = useSelector<ReduxState, ReduxState['calendar']>(({ calendar }) => calendar);
  // const currentVuewAutoSuggestionsSourceTasksCount = useSelector<ReduxState, number>(
  //   (state) => state.ui.currentVuewAutoSuggestionsSourceTasksCount
  // );
  const viewDatesRange = useSelector<ReduxState, ReduxState['ui']['viewDatesRange']>(
    (state) => state.ui.viewDatesRange
  );
  // const scheduleQueueTasks = useSelector(getTasksForScheduleQueue);
  // const shortlistedTasks = useSelector(getShortlistedTasks);
  // const overdueTasks = useSelector(getOverdueTasks);
  // const showOverdueOnly = useSelector<ReduxState, boolean>((state) => state.ui.filter.showOverdueOnly);
  // const isSchedulingQueueEmpty = !scheduleQueueTasks?.length && !shortlistedTasks?.length;
  const [isGeneratingShortlist, setIsGeneratingShortlist] = useState(false);
  const dispatch = useDispatch();
  const [planOptions, setPlanOptions] = useState(false); // Expands widget to show Task Selector and Scope Selector
  const [expand, setExpand] = useState<boolean>(false);

  const isMobile = useMediaQuery({ query: mobileScreen });

  const [loading, setLoading] = useState(false);
  const [loadingUndo, setLoadingUndo] = useState(false);
  const [undoCallback, setUndoCallback] = useState<{ undo: () => Promise<unknown> }>();

  // const selectedListId = useSelector<ReduxState, string>((state) => state.ui.selectedListId);
  const {
    autoSuggestSource: taskSelector,
    autoGenerated: autoSuggestSourceAutoGenerated,
    canSwitchSource,
    isSchedueQueueDisabled,
    isOverdueDisabled,
    isListDisabled,
  } = useAutoSuggest();
  console.log('useAutoSuggest result:', {
    taskSelector,
    canSwitchSource,
    isSchedueQueueDisabled,
    isOverdueDisabled,
    isListDisabled,
  });

  // const isQueueSelected = selectedListId === '__schedule_queue__';
  // const isListSelected = !!selectedListId && !isQueueSelected;

  const taskSelectorOptions = [
    { value: 'hub_llm', title: 'Task Hub', description: `Trevor AI predicts the best tasks to schedule` },
    {
      value: 'queue',
      title: 'Scheduling Queue',
      description: `Only schedule tasks from Scheduling Queue`,
      disabled: isSchedueQueueDisabled, //scheduleQueueTasks.length === 0,
    },
    {
      value: 'overdue',
      title: 'Overdue',
      description: `Only reschedule Overdue tasks`,
      disabled: isOverdueDisabled, //!overdueTasks.length,
    },
    {
      value: 'list',
      title: 'Opened List or Filter',
      description: `Open a List to schedule its tasks`,
      disabled: isListDisabled, //!isListSelected,
    },
  ];

  // const initialTaskSelector = storedTaskSelector || taskSelectorOptions[0].value;
  // const [taskSelector, setTaskSelector] = useState(initialTaskSelector);
  // const storedTaskSelector = localStorage.getItem('PlanMyDay_taskSelector');
  // const taskSelector = useSelector<ReduxState, ReduxState['calendar']['autoSuggestSource']>(
  //   (state) => state.calendar.autoSuggestSource
  // );

  const loadingSuggestions = isGeneratingShortlist || (autoSuggestMode && !suggestionEvents);
  const suggestionsLoaded = canAcceptAllSuggestions && suggestionEvents && suggestionEvents.length > 0;
  const couldNotGenerateSuggestions = canAcceptAllSuggestions && suggestionEvents && suggestionEvents.length === 0;

  // useEffect(() => {
  //   if (selectedListId) {
  //     // setTaskSelector(taskSelectorOptions[2].value);
  //     dispatch(actions.setAutoSuggestSource('list'));
  //   } else {
  //     // setTaskSelector(initialTaskSelector);
  //     dispatch(actions.setAutoSuggestSource(null)); // set default from reducers/calendars.ts
  //   }
  // }, [dispatch, selectedListId]);

  // // fallback to Task Hub if Queue is empty
  // useEffect(() => {
  //   // if (!planOptions) return; // enough for redux to load tasks - Commented, not a good UX solution
  //   if (taskSelector === 'queue' && scheduleQueueTasks.length === 0) {
  //     dispatch(actions.setAutoSuggestSource('hub_llm'));
  //   }
  // }, [dispatch, scheduleQueueTasks, taskSelector]);

  // handle case where there are no suggestions to schedule
  useEffect(() => {
    if (
      //planOptions &&
      !loadingSuggestions &&
      autoSuggestMode &&
      suggestionEvents &&
      !suggestionEvents.length
    ) {
      // dispatch(actions.setAutoSuggestMode(false));
      // dispatch(actions.setAutoSuggestSource(null));
      // setPlanOptions(true);
      Notification.show('No Suggestions for selected dates. Try different planning options.');
    }
  }, [autoSuggestMode, dispatch, loadingSuggestions, planOptions, suggestionEvents]);

  // useEffect(() => {
  //   if (!autoSuggestMode && showOverdueOnly) {
  //     dispatch(actions.setAutoSuggestSource('overdue'));
  //   }
  //   if (!overdueTasks?.length && taskSelector === 'overdue') {
  //     dispatch(actions.setAutoSuggestSource(null)); // set default from reducers/calendars.ts
  //   }
  // }, [autoSuggestMode, dispatch, overdueTasks?.length, showOverdueOnly, taskSelector]);

  const handleTaskSelectorChange = (newTaskSelector: AutoSuggestSource) => {
    dispatch(actions.setAutoSuggestSource(newTaskSelector));
    // setTaskSelector(newTaskSelector);
    if (newTaskSelector === 'hub_llm' || newTaskSelector === 'queue') {
      // localStorageSetItem('PlanMyDay_taskSelector', newTaskSelector);
      saveAutoSuggestSourceToLocalStorage(newTaskSelector);
      defaultAutoSuggestSource = newTaskSelector;
    }
  };

  // if (!canAcceptAllSuggestions || (!suggestionEvents.length && !eventIds?.length)) return null;

  const handleAccept = async () => {
    try {
      setLoading(true);

      let result: { undo: () => Promise<unknown> } | undefined = undefined;

      if (overdueMode) {
        result = await performOverdueReschedule({ suggestionEvents });
        dispatch(actions.setAutoSuggestSource(null));
      } else {
        result = await performBulkCreateEventsFromSuggestions({ suggestionEvents });
      }

      Notification.show('Scheduled successfully');

      setUndoCallback(result);
    } catch (err) {
      logError(err, { suggestionEvents, overdueMode });
      Notification.showError('Failed to schedule. It has been reported.');
    } finally {
      setLoading(false);
      dispatch(actions.setAutoSuggestMode(false)); // We always want to default to false (overdues are rescheduled from false, so inverting it with !autoSuggestMode doesn't help
      // saveAutoSuggestModeToLocalStorage(false);
    }
  };

  const handleRetry = () => {
    // TODO
  };

  const handleUndo = async () => {
    if (!undoCallback?.undo) return;
    try {
      setLoadingUndo(true);
      const res = await undoCallback.undo();
      console.log('Undo result:', res);
      Analytics.event({ category: 'Scheduling', action: 'Undo Last Scheduling Change' });
      setUndoCallback(undefined);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingUndo(false);
    }
  };

  const handleGenerateShortlist = () => {
    setUndoCallback(undefined);
    // const hint = 'I do not like this suggestion, please try again';
    setIsGeneratingShortlist(true);
    reduceTasksWithLLM(viewDatesRange.start, viewDatesRange.end /*hint*/)
      .then((res) => {
        console.log('reduceTasksWithLLM result:', res);
        Analytics.event({ category: 'Schedule', action: 'Feeling Lucky' }); //
        dispatch(actions.setShowSuggestionsForShortlistedTaskIds(res));

        // activate auto suggest mode
        setUndoCallback(undefined);
        dispatch(actions.setAutoSuggestMode(!autoSuggestMode));
      })
      .catch((err) => {
        logError(err, { viewDatesRange, hint: 'reduceTasksWithLLM' });
        Notification.showError('Something went wrong, please try again.');
      })
      .finally(() => {
        setIsGeneratingShortlist(false);
      });
  };

  const handleSuggestionsToggle = () => {
    setUndoCallback(undefined);
    if (!autoSuggestMode && taskSelector === 'hub_llm') {
      handleGenerateShortlist(); // Task Hub is selecter
    } else {
      dispatch(actions.setAutoSuggestMode(!autoSuggestMode)); // Queue, Overdue or List is selected
    }
    setPlanOptions(false); // hide options if they are no longer needed
    // saveAutoSuggestModeToLocalStorage(!autoSuggestMode);
    Analytics.event({ category: 'Schedule', action: `Plan My Day mode: ${taskSelector}` }); // Key Metric
  };

  const handleClose = () => {
    setUndoCallback(undefined);
    dispatch(actions.setAutoSuggestMode(false));
    // saveAutoSuggestModeToLocalStorage(false);
  };

  const acceptText = !overdueMode
    ? `Schedule ${suggestionEvents?.length} suggestions`
    : `Reschedule ${suggestionEvents?.length} overdue tasks`;

  const btnStyle = {
    color: 'black',
    fontSize: '12px',
    lineHeight: '16px',
    maxWidth: '162px',
    maxHeight: '40px',
    textAlign: 'left',
    padding: '8px 4px',
    borderRadius: 'inherit',
    '& .MuiButton-icon': { margin: '0', padding: '10px' },
    '& .MuiLoadingButton-loadingIndicator': { left: '14px' },
  };

  // let fadedColor = ((theme: any) => theme.palette.faded.main)(theme);

  // console.log('TEST Loading: ', loadingSuggestions);
  // console.log('TEST Loaded: ', suggestionsLoaded);
  // console.log('TEST AutoSuggestMode: ', autoSuggestMode);
  // console.log('TEST AutoSuggestSource: ', taskSelector);
  // console.log('TEST SuggestionEvents: ', suggestionEvents.length);
  // console.log('TEST isSchedulingQueueEmpty: ', isSchedulingQueueEmpty);
  // console.log('TEST scheduleQueueTasks: ', scheduleQueueTasks.length);

  const isShowingSingleTaskSuggestions = suggestionEvents?.length > 0 && !canAcceptAllSuggestions;
  const showNoOverdueSuggestions = !!suggestionEvents && !suggestionEvents.length && taskSelector === 'overdue';
  const showAcceptButton = suggestionsLoaded && (autoSuggestMode || taskSelector === 'overdue');
  const showPlanMyDayButton = (!autoSuggestMode && !isGeneratingShortlist) || isShowingSingleTaskSuggestions;

  // Show PlanOptions when there are no suggestions to show
  // useEffect(() => {
  //   if (showNoOverdueSuggestions || (autoSuggestMode && couldNotGenerateSuggestions)) {
  //     setPlanOptions(true);
  //   }
  // }, [showNoOverdueSuggestions, couldNotGenerateSuggestions]);

  const showExpandPlanOptionsButton = !planOptions && showPlanMyDayButton && !loadingSuggestions && expand;

  // print all falgs for debug
  console.log('SuggestionsActionPanel:', {
    showNoOverdueSuggestions,
    autoSuggestMode,
    couldNotGenerateSuggestions,
    loadingSuggestions,
    showPlanMyDayButton,
    showAcceptButton,
    showExpandPlanOptionsButton,
  });

  let planMyDayButton;
  if (
    showPlanMyDayButton
    // && taskSelector !== 'overdue'
  ) {
    if (!isMobile || planOptions) {
      planMyDayButton = (
        <Tooltip title="Generate a plan tailored to you." placement="top" disableInteractive>
          <Button
            variant="text"
            startIcon={<AutoAwesome fill="black" />}
            sx={{
              background: planOptions ? (theme: any) => theme.button.fadedDark : 'transparent',
              animation: planOptions ? 'pulsate 1.5s ease-in-out infinite' : 'none',
              '@keyframes pulsate': {
                '0%': { boxShadow: (theme: any) => '0 0 0 0 ' + theme.button.fadedDark },
                '50%': {
                  boxShadow: (theme: any) => '0 0 0 2px ' + theme.button.fadedDark,
                  borderRadius: '24px 2px 2px 24px',
                },
                '100%': { boxShadow: (theme: any) => '0 0 0 0 ' + theme.button.fadedDark },
              },
              '&:hover': {
                animation: 'none',
                background: (theme: any) => theme.button.fadedDark + ' !important',
                boxShadow: (theme: any) => '0 0 0 2px ' + theme.button.fadedDark + ' !important',
                borderRadius: '24px 2px 2px 24px !important',
              },
              color: 'black',
              padding: '8px 12px',
              borderRadius: planOptions ? '24px' : 'inherit',
            }}
            onClick={handleSuggestionsToggle}
          >
            <span style={{ textWrap: 'nowrap' }}>Plan My Day</span>
            <span
              style={{
                position: 'absolute',
                borderRadius: 'inherit',
                display: planOptions ? 'none' : 'block',
                fontSize: '0.55em',
                color: 'rgba(0,0,0,0.3)',
                right: '12px',
                bottom: '0px',
              }}
            >
              {taskSelector ? taskSelectorOptions.find((option) => option.value === taskSelector)?.title : ''}
            </span>
          </Button>
        </Tooltip>
      );
    } else {
      planMyDayButton = (
        <Tooltip title="Generate a plan tailored to you." placement="top" disableInteractive>
          <FabulousFab onClick={() => setPlanOptions(true)}>
            <AutoAwesome fill="black" />
          </FabulousFab>
        </Tooltip>
      );
    }
  }

  return (
    <Box
      className="animated fadeInDown"
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        minWidth: '8%',
        zIndex: 2, // above the calendar's prev-day button
        // transition: 'min-width 0.5s ease', // Added transition for smooth width animation
        animationDelay: '1.5s',
      }}
      onMouseEnter={() => !isMobile && setExpand(true)}
      onMouseLeave={() => setExpand(false)}
    >
      <Box
        sx={{
          display: 'inline-flex',
          // flexDirection: 'row',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '8px',
          padding: '4px',
          borderRadius: '24px 8px 24px 24px',
          background: 'rgba(255,255,255,1)',
          boxShadow: '0px 0px 15px rgba(0,0,0,0.15)',
          backdropFilter: 'blur(5px)',
          '&:hover': { boxShadow: '0px 0px 25px rgba(0,0,0,0.15)' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            gap: '2px',
            '& > :not(:first-child)': {
              borderRadius: '0px',
              borderTopRightRadius: '4px',
              borderLeft: '1px solid rgba(0,0,0,0.1)',
            },
            '& > :last-child': {
              borderBottomRightRadius: planOptions ? '0px' : '20px',
            },
          }}
        >
          {showAcceptButton && (
            <>
              <Tooltip
                title={
                  taskSelector !== 'overdue'
                    ? 'Alternatively, click on individual suggestions to accept them.'
                    : 'Alternatively, click on individual overdue suggestions to resolve them.'
                }
                placement="top"
                disableInteractive
              >
                <LoadingButton
                  variant="text"
                  loading={loading}
                  onClick={handleAccept}
                  startIcon={loading ? undefined : <Check sx={{ color: 'black' }} />}
                  sx={btnStyle}
                >
                  {acceptText}
                </LoadingButton>
              </Tooltip>
              {/* <Tooltip title="Remake Plan" placement="top" disableInteractive>
              <IconButton aria-label="retry" onClick={handleRetry}>
                <Replay />
              </IconButton>
            </Tooltip> */}
              {/* {taskSelector !== 'overdue' && ( */}
              <Tooltip title="Discard suggestions" placement="top" disableInteractive>
                <IconButton onClick={handleClose} aria-label="close">
                  <Close />
                </IconButton>
              </Tooltip>
              {/* )} */}
            </>
          )}
          {(showNoOverdueSuggestions || (autoSuggestMode && couldNotGenerateSuggestions)) && (
            <LoadingButton
              variant="text"
              // loadingPosition="start"
              disabled
              // startIcon={<CircularProgress color="inherit" size={16} />}
              startIcon={<EventBusy color="inherit" />}
              sx={btnStyle}
            >
              No Suggestions for time frame
            </LoadingButton>
          )}
          {planMyDayButton}
          {loadingSuggestions && (
            <LoadingButton
              variant="text"
              loading={true}
              loadingPosition="start"
              startIcon={<CircularProgress color="inherit" size={16} />}
              sx={btnStyle}
            >
              {isGeneratingShortlist ? 'Analyzing tasks...' : 'Generating Plan...'}
            </LoadingButton>
          )}
          {!!undoCallback && (
            <Tooltip title={`Undo last change`} placement="right" disableInteractive>
              <FabulousFab onClick={handleUndo} disabled={loadingUndo}>
                {loadingUndo ? <CircularProgress /> : <Undo color="info" sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />}
              </FabulousFab>
            </Tooltip>
          )}
          {showExpandPlanOptionsButton && (
            <Tooltip title="Show Scheduling Options" placement="top" disableInteractive>
              <IconButton
                aria-label="expand"
                onClick={() => setPlanOptions(true)}
                sx={{
                  color: 'grey',
                }}
              >
                <ExpandMore />
              </IconButton>
            </Tooltip>
          )}
          {planOptions && (
            <Tooltip title="Hide Scheduling Options" placement="top" disableInteractive>
              <IconButton
                aria-label="close"
                onClick={() => setPlanOptions(false)}
                sx={{
                  color: 'black',
                }}
              >
                {/* <Close /> */}
                <ExpandLess />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <Collapse in={planOptions} unmountOnExit timeout={300} sx={{ width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '1rem',
              // padding: '4px 0',
              // width: '100%',
              // minWidth: '210px',
            }}
          >
            <PillSelector
              options={taskSelectorOptions}
              value={taskSelector}
              disabled={!canSwitchSource} //{isQueueSelected || isListSelected || showOverdueOnly}
              onChange={handleTaskSelectorChange}
              fullWidth
              helperText="Select tasks to schedule"
            />
            <ScopeSelector />
            <Button
              variant="text"
              color="info"
              fullWidth
              onClick={() => {
                // window.location.href = '/app/settings#preferences')
                router.push('/app/settings#preferences');
              }}
              sx={{ borderRadius: '20px', padding: '8px 16px' }}
            >
              Go To Preferences
            </Button>
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
};

import React, { FC, MutableRefObject, PropsWithChildren } from 'react';

const scrollIntoViewAndHighlight = (element) => {
  let wrap = (window as any).$(element);
  let raw = wrap.get(0);
  if (raw.scrollIntoViewIfNeeded) {
    raw.scrollIntoViewIfNeeded();
  } else if (raw.scrollIntoView) {
    raw.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }
  wrap.effect('highlight', { color: 'rgba(80,150,190,0.1)' }); //, 3000)
};

export const useScrollIntoViewOnMount = (activate: boolean, ref: MutableRefObject<HTMLDivElement>) => {
  React.useEffect(() => {
    if (activate && ref.current) {
      scrollIntoViewAndHighlight(ref.current);
    }
  }, [activate, ref]);
};

const ScrollIntoViewOnMount: FC<PropsWithChildren<{ activate: boolean }>> = ({ activate, children }) => {
  const ref = React.useRef(null);
  useScrollIntoViewOnMount(activate, ref);
  return <div ref={ref}>{children}</div>;
};

export default ScrollIntoViewOnMount;

import { useState, useRef } from 'react';
import { ListItemIcon, ListItemButton, ListItemText, Menu } from '@mui/material';
import ArrowDropRight from '@mui/icons-material/ArrowRight';
import MoveToInbox from '@mui/icons-material/MoveToInbox';
import * as taskOps from '../operations/task';
import { getEnabledLists } from 'reducers/tasks';
import { ListNameWithIcon } from 'components';
import { defaultColor } from 'common/utils';
import { useSelector } from 'react-redux';

const MoveToListMenuItem = ({
  // children,
  task,
  button = null,
  // eventTaskList
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const ref = useRef();
  const enabledLists = useSelector(getEnabledLists);

  const listItems = enabledLists.map((list) => (
    <ListItemButton
      key={list.id}
      onClick={() => {
        taskOps.moveTaskToList(task, list.id);
        setAnchorEl(null);
      }}
      style={
        list.id === task.listId
          ? { color: 'white', backgroundColor: list.color || defaultColor }
          : { color: list.color }
      }
    >
      <ListNameWithIcon list={list} margin="0 4px 0 5px" color={list.id === task.listId ? 'white' : undefined} />
    </ListItemButton>
  ));

  let content;
  if (button) {
    content = (
      <a ref={ref} href="#" onClick={() => setAnchorEl(ref.current)} style={{ textDecoration: 'none' }}>
        {button}
      </a>
    );
  } else {
    content = (
      <ListItemButton ref={ref} onClick={() => setAnchorEl(ref.current)}>
        <ListItemIcon>
          <MoveToInbox />
        </ListItemIcon>
        <ListItemText>Move to List</ListItemText>
        <ArrowDropRight />
      </ListItemButton>
    );
  }

  return (
    <>
      {content}
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => setAnchorEl(null)}
      >
        {listItems}
      </Menu>
    </>
  );
};

export default MoveToListMenuItem;

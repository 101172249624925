import { FC, useState } from 'react';
import { Button, IconButton, Box, Tooltip } from '@mui/material';
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { Close, SportsScore } from '@mui/icons-material';
import { formatDueScheduledDate } from '.';

interface SelectDueDateWidgetProps {
  dueDate: string | undefined;
  hideSetButton?: boolean;
  onChange: (date: string | null) => void;
  buttonProps?: object;
}

export const SelectDueDateWidget: FC<SelectDueDateWidgetProps> = ({
  dueDate,
  hideSetButton = false,
  onChange,
  buttonProps = {},
}) => {
  const [show, setShow] = useState(false);
  const [modifiedDate, setModifiedDate] = useState(null);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box style={{ display: 'inline-flex', alignItems: 'center', width: '100%' }} className="task-due-date-widget">
        <MobileDatePicker
          value={modifiedDate || dueDate}
          open={show}
          onChange={setModifiedDate}
          onAccept={(date) => {
            if (modifiedDate) {
              onChange(moment(modifiedDate).format('YYYY-MM-DD'));
            }
            setModifiedDate(null);
            setShow(false);
          }}
          onClose={() => {
            setModifiedDate(null);
            setShow(false);
          }}
          renderInput={(params) => (
            <Tooltip title={hideSetButton ? 'Due date' : 'Set due date'} placement="top" disableInteractive>
              <span
                style={{
                  flexGrow: 1,
                  maxWidth: '100%',
                  // height: '36px'
                }}
              >
                <Button
                  onClick={() => setShow(true)}
                  disabled={hideSetButton}
                  fullWidth
                  variant={'outlined'}
                  startIcon={<SportsScore />}
                  sx={{ borderRadius: !!dueDate && !hideSetButton ? '8px 2px 2px 8px' : '8px', ...buttonProps }}
                >
                  {dueDate ? formatDueScheduledDate(dueDate) : 'Due date'}
                </Button>
              </span>
            </Tooltip>
          )}
        />
        {!!dueDate && !hideSetButton && (
          <Tooltip title="Clear due date" placement="top" disableInteractive>
            <IconButton
              onClick={() => onChange(null)}
              sx={{
                height: '36px',
                width: '36px',
                margin: '0 0 0 4px',
                padding: '4px',
                borderRadius: '2px 8px 8px 2px',
                border: '1px solid rgba(80, 150, 190, 0.5)',
                ...buttonProps,
              }}
              color="primary"
            >
              <Close />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </LocalizationProvider>
  );
};

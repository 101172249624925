import moment from 'moment';
import { Planner, PlannerZone, ShallowEvent, zoneDurationMinutes } from './planner';
import { isDueDateWithTime, generateDatesRange, getDatesFromAllDayEvent, isRecurringTask, zonesForDay } from './utils';
import { PlanningContext, PlanningStrategyFunction } from './types';
import { MinAllDayDuration, taskDuration } from './utils';
import { Suggestion, SuggestionWithScore } from './types';
import { wrapToTimeGrid, momentToFloat } from './utils';

const scorePresetDefault = {
  predictionDay: 0.3,
  predictionTime: 0.5,
  soonerBetter: 0.2,
  underdue: 12,
  due: 12,
  overdue: 7,
  longerDuration: 0.2,
  adjacentPressure: -0.3,
};

const scorePresetDueDateIsExact = {
  ...scorePresetDefault,
  underdue: 10,
  due: 20,
  overdue: 7,
};

const calculateScore = (
  ctx: PlanningContext,
  suggestion: Suggestion,
  preset: typeof scorePresetDefault,
  adjacentEvents?: ShallowEvent[]
) => {
  const { momenttz } = ctx;
  const { task, pred, beginDate, duration, allDay } = suggestion;

  let scorePieces = {
    day: 0,
    time: 0,
    soonerBetter: 0,
    underdue: 0,
    due: 0,
    overdue: 0,
    longerDuration: 0,
    adjacentPressure: 0,
  };

  const { start_times, days } = pred;

  const day = beginDate.format('ddd'); // Mon, Tue, Wed, Thu, Fri, Sat, Sun
  const matchingDayProb = days.find(({ value: predictedDay }) => day === predictedDay)?.prob || 0;
  scorePieces.day = (matchingDayProb / 100) * preset.predictionDay;

  const diffFromToday = beginDate.diff(momenttz(), 'days') || 0.5;
  scorePieces.soonerBetter = preset.soonerBetter / diffFromToday;

  // TODO take timezone into account if on server
  const beginDateMinutes = beginDate.hour() * 60 + beginDate.minute();
  const startTimeScores = start_times.map(({ value: predictedTimeMinutes, prob }) => {
    const diffCoeff = Math.abs(predictedTimeMinutes - beginDateMinutes) / 3600; // 0..1

    return preset.predictionTime * (1 - diffCoeff) * (prob / 100);
  });

  if (!allDay) {
    scorePieces.time = Math.max(...startTimeScores);
    scorePieces.longerDuration = preset.longerDuration * (Math.min(duration, 480) / 480);
  }

  if (adjacentEvents) {
    const count = adjacentEvents.length;
    scorePieces.adjacentPressure = preset.adjacentPressure * (count / 10);
  }

  if (task.dueDate) {
    const hasDueTime = isDueDateWithTime(task.dueDate);
    const dueDateDiff = momenttz(task.dueDate).endOf('day').diff(moment(beginDate).endOf('day'), 'days');
    if (dueDateDiff > 0) {
      scorePieces.underdue = preset.underdue / Math.abs(dueDateDiff);
    } else if (dueDateDiff < 0) {
      scorePieces.overdue = preset.overdue + 5 / (5 + Math.abs(dueDateDiff));
    } else {
      scorePieces.due = preset.due;

      if (hasDueTime) {
        const mdue = momenttz(task.dueDate);
        const minutes = mdue.hour() * 60 + mdue.minute();
        const diffCoeff = Math.abs(minutes - beginDateMinutes) / 3600; // 0..1

        scorePieces.due += preset.predictionTime * (1 - diffCoeff);
      }
    }
  }

  const score = Object.values(scorePieces).reduce((acc, val) => acc + val, 0);
  // console.log('calculateScore', beginDate.format(), scorePieces, score, task.title, pred);
  return { score, scorePieces };
};

interface DateRangeZone {
  date: moment.Moment;
  dateStr: string;
  freeZones: PlannerZone[];
}

export const planningStrategyPredicted: PlanningStrategyFunction = async ({
  ctx,
  tasksWithPredictions: _tasksWithPredictions,
  fromDate,
  toDate,
  events,
  limit,
}) => {
  const {
    momenttz,
    prefs: {
      user_schedule,
      default_task_duration: defaultTaskDuration,
      due_date_is_exact_date: dueDateIsExactDate,
      recurring_due_date_is_exact_date: recurringDueDateIsExactDate,
      max_all_day_events: maxAllDayEvents,
    },
  } = ctx;
  const timeNow = momenttz();

  const { eventsStartEnds, eventsAllDayByDate } = events.reduce(
    (acc, ev) => {
      if (ev.allDay) {
        for (const date of getDatesFromAllDayEvent(ctx, ev)) {
          const arr = (acc.eventsAllDayByDate[date] = acc.eventsAllDayByDate[date] || []);
          arr.push(ev);
        }
      } else {
        acc.eventsStartEnds.push({ start: ev.beginDate, end: ev.endDate });
      }
      return acc;
    },
    { eventsStartEnds: [], eventsAllDayByDate: {} }
  );
  console.log('planningStrategyPredicted eventsStartEnds', { events, eventsStartEnds, eventsAllDayByDate });

  const suggestions: Suggestion[] = [];

  let count = limit;

  let tasksWithPredictions = _tasksWithPredictions.map(({ task, pred }) => ({
    task,
    pred,
    dur: taskDuration(task, defaultTaskDuration, pred),
  }));

  do {
    let suggestionsWithScore: SuggestionWithScore[] = [];

    const defaultDatesRange = generateDatesRange(ctx, fromDate, toDate);
    // const workTimeStart = user_schedule.zones[0].start / 60;
    // const workTimeEnd = user_schedule.zones[0].end / 60;

    const defaultDatesRangeZones: DateRangeZone[] = generateDateRangeZones(
      defaultDatesRange,
      user_schedule,
      eventsStartEnds,
      timeNow
    );
    const customDatesRangeZones: Record<string, DateRangeZone[]> = {};

    // for (const date of datesRange) {
    //   // let zones = [workTimeStart, workTimeEnd];
    //   if (timeNow.isSame(date, 'date')) {
    //     const nextSlot = moment(timeNow).add(wrapToTimeGrid(timeNow.minutes()), 'minutes');
    //     // zones = [momentToFloat(nextSlot), workTimeEnd];
    //     eventsStartEnds.push({ start: moment().startOf('day').toISOString(), end: nextSlot.toISOString() });
    //   }
    //   const planner = new Planner(date, { zones: user_schedule.zones });
    //   const summary = planner.getSummaryByZone(eventsStartEnds);
    //   const { freeZones } = Object.values(summary)[0];
    //   const dateStr = date.format('YYYY-MM-DD');
    //   datesRangeZones.push({ date, dateStr, freeZones });
    // }
    console.log('summary', defaultDatesRange, defaultDatesRangeZones);

    for (const { task, pred, dur } of tasksWithPredictions) {
      console.log('TODO planningStrategyPredicted', task.title, { task, pred, dur });

      let datesRangeZones = defaultDatesRangeZones;
      if (task.userScheduleConfigItem) {
        const key = JSON.stringify(task.userScheduleConfigItem);
        if (!customDatesRangeZones[key]) {
          customDatesRangeZones[key] = generateDateRangeZones(
            generateDatesRange(ctx, fromDate, toDate, task.userScheduleConfigItem),
            task.userScheduleConfigItem,
            eventsStartEnds,
            timeNow
          );
        }
        datesRangeZones = customDatesRangeZones[key];
        // console.log('use customDatesRangeZones', task.title, key, customDatesRangeZones[key]);
      }

      if (typeof task.notEarlierThanDays === 'number' && task.dueDate) {
        const notEarlierThan = moment(task.dueDate).subtract(task.notEarlierThanDays, 'days');
        datesRangeZones = datesRangeZones.filter(({ date }) => {
          return date.isSameOrAfter(notEarlierThan, 'day');
        });
      }

      const isRecurring = isRecurringTask(task);
      const isExactDate = isRecurring ? recurringDueDateIsExactDate : dueDateIsExactDate;
      const preset = isExactDate ? scorePresetDueDateIsExact : scorePresetDefault;

      if (dur >= MinAllDayDuration) {
        // all-day task
        for (const { date, dateStr } of datesRangeZones) {
          const dateEvents = eventsAllDayByDate[dateStr] || [];
          if (dateEvents.length >= maxAllDayEvents) continue;

          if (dur > MinAllDayDuration) {
            const tooManyEventsOneOfTheDays = getDatesFromAllDayEvent(ctx, {
              beginDate: dateStr,
              endDate: moment(date).add(dur, 'minutes').format('YYYY-MM-DD'),
            }).some((dd) => (eventsAllDayByDate[dd] || []).length >= maxAllDayEvents);

            if (tooManyEventsOneOfTheDays) {
              continue;
            }
          }

          const suggestion = { task, pred, beginDate: date, beginDateStr: dateStr, duration: dur, allDay: true };

          const { score, scorePieces } = calculateScore(ctx, suggestion, preset, dateEvents);

          // console.log('pp', zone.start.format(), task.title, score, scorePieces, {
          //   task,
          //   pred,
          //   zone,
          //   dur,
          // });
          suggestionsWithScore.push({ ...suggestion, score, scorePieces });
        }
        continue;
      }

      for (const { freeZones } of datesRangeZones) {
        for (const zone of freeZones) {
          let start = moment(zone.start);
          do {
            const slice = { start: moment(start), end: zone.end };
            if (zoneDurationMinutes(slice) < dur) break;

            const suggestion = { task, pred, beginDate: slice.start, duration: dur };

            const { score, scorePieces } = calculateScore(ctx, suggestion, preset);

            // console.log('pp', slice.start.format(), task.title, score, scorePieces, {
            //   task,
            //   pred,
            //   slice,
            //   dur,
            // });
            suggestionsWithScore.push({ ...suggestion, score, scorePieces });

            start.add(5, 'minutes');
          } while (start.isBefore(zone.end));
        }
      }
    }

    const topScoredSuggestion = suggestionsWithScore.reduce((acc, sugg) => {
      if (!acc || sugg.score > acc.score) return sugg;
      return acc;
    }, null);

    if (!topScoredSuggestion) break;

    console.log(
      'topScoredSuggestion',
      topScoredSuggestion.task.title,
      topScoredSuggestion,
      'from',
      suggestionsWithScore
    );

    suggestions.push(topScoredSuggestion);

    if (!topScoredSuggestion.allDay) {
      eventsStartEnds.push({
        start: topScoredSuggestion.beginDate,
        end: moment(topScoredSuggestion.beginDate).add(topScoredSuggestion.duration, 'minutes'),
      });
    } else {
      const ev = {
        beginDate: moment(topScoredSuggestion.beginDate).format('YYYY-MM-DD'),
        endDate: moment(topScoredSuggestion.beginDate)
          .add(topScoredSuggestion.duration, 'minutes')
          .endOf('day')
          .format('YYYY-MM-DD'),
        allDay: true,
      };
      for (const date of getDatesFromAllDayEvent(ctx, ev)) {
        const arr = (eventsAllDayByDate[date] = eventsAllDayByDate[date] || []);
        arr.push(ev);
      }
    }

    tasksWithPredictions = tasksWithPredictions.filter(({ task }) => task !== topScoredSuggestion.task);
  } while (--count > 0);

  return suggestions;
};

function generateDateRangeZones(datesRange, user_schedule, eventsStartEnds, timeNow) {
  const datesRangeZones = [];
  for (const date of datesRange) {
    // let zones = [workTimeStart, workTimeEnd];
    if (timeNow.isSame(date, 'date')) {
      const nextSlot = moment(timeNow).add(wrapToTimeGrid(timeNow.minutes()), 'minutes');
      // zones = [momentToFloat(nextSlot), workTimeEnd];
      eventsStartEnds.push({ start: moment().startOf('day').toISOString(), end: nextSlot.toISOString() });
    }
    const planner = new Planner(date, { zones: zonesForDay(user_schedule, date.day()) });
    const summary = planner.getSummaryByZone(eventsStartEnds);
    const summaryValues = Object.values(summary);
    for (const { freeZones } of summaryValues) {
      const dateStr = date.format('YYYY-MM-DD');
      datesRangeZones.push({ date, dateStr, freeZones });
    }
  }
  return datesRangeZones;
}

import React, { useState, useEffect, useRef, FC } from 'react';
import { Note } from '../../components';
import * as eventOps from '../../operations/event';
import * as taskOps from '../../operations/task';
import * as sharingOps from '../../operations/sharing';
import { Box, Button, Paper, Collapse, Grid, Tooltip } from '@mui/material';
import { ArrowForward, EventBusy, PlaylistAddCheck } from '@mui/icons-material';
import { SmartSuggestions, SmartSuggestionsButtons } from './SmartSuggestions';
import { TaskTitleWithControls } from './TaskTitleWithControls';
import { ListNameWithIcon } from 'components';
import { SelectDueDateWidget } from './SelectDueDateWidget';
import Analytics from '../../analytics.jsx';
import { QuickSuggestionsDropdown } from './QuickSuggestions';
import { CombinedRecurrenceWidget } from './CombinedRecurrenceWidget';
import { detectOrigin, isDateOverdue } from 'common/utils';
import { SmartSchedulingIcon } from '../../components/CustomIcons';
import { useMutableCallback } from 'common/useMutableCallback';
import { useSelector } from 'react-redux';
import { CenteredSpinner } from 'components/CenteredSpinner';
import { SelectNotEarlierThan } from './SelectNotEarlierThan';
import { List, Task } from 'shared';
import { useUserPrefs } from 'common/useUserPrefs';
import { ReduxState } from 'reducers';
import * as actions from 'actions';
import { useDispatch } from 'react-redux';

const TaskListSubscript = ({ list, onClick }) => {
  return (
    <div className="task-list">
      <a
        href=""
        style={{ color: list.color }}
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
      >
        {/* {'#' + list.name} */}
        <ListNameWithIcon list={list} />
      </a>
    </div>
  );
};

const PostponeButton = (props) => (
  <Button sx={{ height: '34px' }} variant="outlined" fullWidth startIcon={<EventBusy />} {...props}>
    Unschedule
  </Button>
);

interface RenderPreviewProps {
  task: Task;
  onToggleExpand: () => void;
  onLongHover: () => void;
  onLongHoverEnd: () => void;
  onLongPress: () => void;
  onShowTaskOptions: () => void;
  taskOptions: boolean;
}

export interface TaskCardProps {
  task: Task;
  list: List;
  showList: boolean;
  onListClick: any;
  renderPreview?: (props: RenderPreviewProps) => React.ReactNode;
  // showTaskOptions?: boolean;
  // showSchedulingOptions?: boolean;
  // onToggleExpand?: (hint?: boolean) => void;
  showNoteInPreview?: boolean;
  expandNote?: () => void;
  collapseNote?: () => void;
  defaultCollapsed?: boolean;
  analiticsCategory?: string;
}

const defaultRenderPreview = ({
  task,
  onToggleExpand,
}: //  listColor
RenderPreviewProps) => {
  return (
    <TaskTitleWithControls
      task={task}
      onToggleExpand={onToggleExpand}
      listColor="primary"
      onShowTaskOptions={() => {}}
      taskOptions
    />
  );
};

const dummyCb = () => {};

export const TaskCard: FC<TaskCardProps> = React.memo<TaskCardProps>(
  ({
    task,
    list,
    showList,
    onListClick,
    renderPreview = defaultRenderPreview,
    // showTaskOptions = false,
    // showSchedulingOptions = false,
    // onToggleExpand,
    showNoteInPreview = false,
    expandNote,
    collapseNote,
    defaultCollapsed,
    analiticsCategory,
  }) => {
    // const mutableOnToggleExpand = useMutableCallback(onToggleExpand ?? dummyCb);
    // const [prefs, inProgressObj, showSuggestionForTaskId] = useSelector((state) => [
    //   state.account.user.prefs,
    //   state.tasks.inProgressObj,
    //   state.ui.showSuggestionForTaskId,
    // ]);
    const prefs = useUserPrefs();

    const showSuggestionForTaskId = useSelector<ReduxState, string | null>((state) => state.ui.showSuggestionForTaskId);
    const showOptionsForTaskId = useSelector<ReduxState, string | null>((state) => state.ui.showOptionsForTaskId);
    const showTaskOptions = showOptionsForTaskId === task.id;
    const showSchedulingOptions = showSuggestionForTaskId === task.id;

    const setShowQuickSuggestionForTaskId = useSelector<ReduxState, string | null>(
      (state) => state.ui.showQuickSuggestionForTaskId
    );

    const inProgressObj = useSelector<ReduxState, ReduxState['tasks']['inProgressObj']>(
      (state) => state.tasks.inProgressObj
    );
    const suggestionEvents = useSelector<ReduxState, ReduxState['calendar']['suggestionEvents']>(
      (state) => state.calendar.suggestionEvents
    );
    const refSuggestions = useRef(suggestionEvents);
    refSuggestions.current = suggestionEvents;

    const dispatch = useDispatch();

    const isInProgress = inProgressObj[task.id];

    // const [expanded = hintExpand, setExpanded] = useState();
    const expanded = showSchedulingOptions || showTaskOptions;
    // const [isTaskOptions, setIsTaskOptions] = useState(false);
    // const showTaskOptions = expanded && isTaskOptions;
    // const showSchedulingOptions = expanded && !isTaskOptions;

    const autoAddedToQueue = prefs.due_date_in_scheduling_queue && !!task.dueDate;
    const inQueue = task.queue || autoAddedToQueue;

    const taskCardRef = useRef<HTMLDivElement>();
    // const [expanded, setExpanded] = useState(initialExpand);
    if (expanded) {
      console.log('expanded task:', task);
    }

    const taskId = task.id;

    useEffect(() => {
      // if (taskOptions || expanded) {
      if (expanded) {
        if (showTaskOptions) {
          // Auto-focus the Note field in the referenced task with task options open
          setTimeout(() => {
            if (taskCardRef.current) {
              const noteField = taskCardRef.current.querySelector('.ql-editor');
              if (noteField) {
                (noteField as HTMLDivElement).focus();
                // workaround to focus the END of the <p> string in Quill
                const range = document.createRange();
                range.selectNodeContents(noteField);
                range.collapse(false);
                const selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);
              }
            }
          }, 0);
        }

        // Close task options and/or schedule options on click outside of the referenced task card
        const handleClickOutside = (event) => {
          if (taskCardRef.current && !taskCardRef.current.contains(event.target)) {
            const appRoot = document.getElementById('app_root'); // Prevent closing when clicking popovers and dialogs
            const calendarClasses = [
              'previous-day-button',
              'next-day-button',
              'fc-view-harness',
              'fc-header-toolbar',
              'calendar-mode-button',
            ]; // Prevent closing when managing calendar
            const calendarElements = calendarClasses.map((className) => document.getElementsByClassName(className));
            if (
              appRoot &&
              appRoot.contains(event.target) &&
              !calendarElements.some((elements) =>
                Array.from(elements).some((element) => element.contains(event.target))
              )
            ) {
              setTimeout(() => {
                // setTaskOptions(false);
                // setExpanded(false);
                // mutableOnToggleExpand(false);
                dispatch(actions.clearShowOptionsSuggestionsForTaskId(taskId));
              }, 80); // allows for consecuitve opening of task scheduling options on different task cards. Do not set to 0.
            }
          }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }
    }, [
      dispatch,
      taskId,
      showTaskOptions,
      expanded,
      //  mutableOnToggleExpand
    ]);

    const refManualExpand = useRef(false);

    const handleShowSchedulingOptions = () => {
      console.log('handleShowSchedulingOptions', { expanded, showSchedulingOptions, showTaskOptions });
      if (
        showSchedulingOptions
        // && !isTaskOptions
      ) {
        dispatch(actions.clearShowOptionsSuggestionsForTaskId(task.id));
        refManualExpand.current = false;
        //   return onToggleExpand?.(false);

        // }
        // setIsTaskOptions(false);
        // if (!expanded) {
      } else {
        dispatch(actions.setShowSuggestionsForTaskId(task.id));
        refManualExpand.current = true;
        // onToggleExpand?.(true);
      }
      Analytics.event({
        category: analiticsCategory ?? 'Task',
        action: 'Scheduling Options Toggle',
      });
    };

    const handleShowTaskOptions = () => {
      console.log('handleShowTaskOptions', { expanded, showTaskOptions, showSchedulingOptions });
      // if (
      // expanded
      // && isTaskOptions
      // ) {
      //   _handleToggleExpand();
      // }
      // setIsTaskOptions(true);
      // if (!expanded) {
      //   onToggleExpand?.(true);
      // }

      if (showTaskOptions) {
        dispatch(actions.clearShowOptionsSuggestionsForTaskId(task.id));
      } else {
        dispatch(actions.setShowOptionsForTaskId(task.id));
      }
    };

    const handleLongHover = () => {
      if (!showSuggestionForTaskId) {
        dispatch(actions.setShowQuickSuggestionForTaskId(task.id));
        // dispatch(actions.setShowSuggestionsForTaskId(task.id));
      }
    };
    const handleLongHoverEnd = () => {
      if (setShowQuickSuggestionForTaskId) {
        dispatch(actions.setShowQuickSuggestionForTaskId(null));
      }
      // if (showSuggestionForTaskId && !refManualExpand.current) {
      //   dispatch(actions.clearShowSuggestionsForTaskId(task.id));
      // }
    };

    const handleLongPress = () => {
      console.log('long press', refSuggestions.current);
      if (refSuggestions.current?.length === 1) {
        const suggestionEvent = refSuggestions.current[0];
        eventOps
          .scheduleOrRescheduleTask(task, suggestionEvent, prefs.default_task_duration)
          .then(() => {
            console.log('Task scheduled:', task);
          })
          .catch((error) => {
            console.error('Error scheduling task:', error);
          });

        dispatch(actions.setShowQuickSuggestionForTaskId(null));
      }
    };

    // TODO: Focus note on taskOptions = true
    const noteWidget = (
      <Note
        className="task-note"
        key={'note-' + task.id}
        uniqueKey={'note-' + task.id}
        content={task.note}
        onSave={(content) => sharingOps.saveTaskNote(task, content)}
        expand={expandNote}
        collapse={collapseNote}
        defaultCollapsed={defaultCollapsed}
      />
    );

    const toggleSchedulingQueue = (task) => {
      taskOps.toggleSchedulingQueue(task);
      handleShowSchedulingOptions();
    };

    let schedulingSuggestionButtons = (
      <>
        <SmartSuggestionsButtons task={task} />
        <Box sx={{ display: 'inline-flex', paddingLeft: '4px', paddingTop: '4px', width: '100%' }}>
          <QuickSuggestionsDropdown
            task={task}
            styles={{
              height: '34px',
            }}
          />
        </Box>
      </>
    );
    if (task.recurringEventIds) {
      // hide if recurring scheduled
      schedulingSuggestionButtons = null;
    }

    const taskOrigin = detectOrigin(task.id);

    let schedulingOps;
    if (task.eventId || task.recurringEventIds) {
      schedulingOps = (
        <>
          <Grid item xs={12} sm={!task.completed ? 12 : 6}>
            <Button
              fullWidth
              variant="outlined"
              sx={{ height: '34px' }}
              onClick={() => {
                (window as any).calendar.gotoDate(task.eventBeginDate);
                Analytics.event({ category: 'Schedule', action: 'Show in Schedule' });
              }}
              startIcon={<ArrowForward />}
            >
              Show in Schedule
            </Button>
          </Grid>
          {!task.completed && (
            <Grid item xs={12} sm={6}>
              <Tooltip
                title="Unfinished - Saves the Calendar Event and returns the Task to the Task Hub for rescheduling."
                placement="top"
                disableInteractive
              >
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => eventOps.eventPartialComplete(task.eventId)}
                  startIcon={<PlaylistAddCheck />}
                >
                  Unfinished
                </Button>
              </Tooltip>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <PostponeButton
              onClick={() => eventOps.eventPostponeById(task.eventId, task.id)}
              disabled={task.recurringEventIds ? true : false}
            />
          </Grid>
          {schedulingSuggestionButtons}
        </>
      );
    } else {
      schedulingOps = (
        <>
          {!list.queue && (
            <Tooltip
              title="Adds the task to Trevor's AI scheduling queue. The tasks are then shown as scheduling suggestions in the timeline at their predicted time slots and can easily be scheduled individually or in bulk. Tasks with a due date are added automatically."
              disableInteractive
            >
              <Grid item xs={12} sm={12}>
                <Button
                  className="smart-scheduling-button"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  startIcon={<SmartSchedulingIcon color="inherit" size="18px" />} // TODO: remove (soon) and replace icon with Trevor Icon
                  onClick={() => toggleSchedulingQueue(task)}
                  disabled={autoAddedToQueue}
                >
                  {inQueue ? 'Remove from' : 'Add to'} Scheduling Queue
                </Button>
              </Grid>
            </Tooltip>
          )}
          {!task.recurringEventIds && (
            <>
              <Grid item xs={12} sm={6}>
                <SelectDueDateWidget
                  dueDate={task.dueDate}
                  hideSetButton={!!task.recurrence || taskOrigin !== 'trevor'}
                  onChange={(date) => taskOps.setTaskDueDate(task.id, date)}
                  buttonProps={{ height: '34px' }}
                />
                {!!task.dueDate && !isDateOverdue(task.dueDate) && (
                  <Box sx={{ marginTop: '5px', textAlign: 'center', '& .MuiTypography-root': { fontSize: '0.8rem' } }}>
                    <SelectNotEarlierThan
                      value={task.notEarlierThanDays}
                      dueDate={task.dueDate}
                      verbose
                      centered
                      onChange={(date) => taskOps.setTaskNotEarlierThan(task.id, date)}
                    />
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                {taskOrigin === 'trevor' && <CombinedRecurrenceWidget task={task} />}
              </Grid>
            </>
          )}
          {schedulingSuggestionButtons}
        </>
      );
    }

    return (
      <Paper
        elevation={2}
        className="task-card"
        ref={taskCardRef}
        sx={{
          // the class here is important - it's used in combination with .ui-sortable-helper during dragging
          borderRadius: 2,
          margin: '3px 0px',
          padding: '1px',
          boxShadow: (theme) => theme.shadow.task,
          [`&:hover`]: {
            boxShadow: (theme) => theme.shadow.taskHover,
            transition: 'box-shadow 0.1s linear',
          },
        }}
      >
        {isInProgress ? (
          <CenteredSpinner />
        ) : (
          renderPreview({
            task,
            onToggleExpand: handleShowSchedulingOptions,
            onShowTaskOptions: handleShowTaskOptions,
            onLongHover: handleLongHover,
            onLongHoverEnd: handleLongHoverEnd,
            onLongPress: handleLongPress,
            taskOptions: showTaskOptions,
          })
        )}
        {/* Shows List in certain filters like "Next 7 days" */}
        {showList && (
          <Box
            sx={{
              padding: '0 20px 10px 0',
              textAlign: 'right',
            }}
          >
            <TaskListSubscript list={list} onClick={() => onListClick?.(list)} />
          </Box>
        )}
        {showNoteInPreview && <Box className="task-note-preview">{noteWidget}</Box>}
        <Collapse in={showTaskOptions}>{showTaskOptions && !showNoteInPreview && noteWidget}</Collapse>
        <Collapse in={showSchedulingOptions}>
          {showSchedulingOptions && (
            <>
              <Grid
                container
                spacing={0.5}
                justifyContent="flex-end"
                sx={{
                  padding: '6px',
                  // gap: '4px 0',
                  ['.MuiButton-root']: {
                    height: '34px',
                    fontSize: '13px',
                    lineHeight: '14px',
                    textOverflow: 'ellipsis',
                  },
                }}
              >
                {schedulingOps}
              </Grid>
            </>
          )}
        </Collapse>
        {(showSuggestionForTaskId === task.id || setShowQuickSuggestionForTaskId === task.id) && (
          <SmartSuggestions
            task={task}
            limit={4}
            // isHovered={showSuggestionForTaskId !== task.id}
            isHovered={setShowQuickSuggestionForTaskId === task.id}
            key={showSuggestionForTaskId === task.id ? 1 : 2}
          />
        )}
      </Paper>
    );
  }
);

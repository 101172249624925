import { Divider, ListItemButton, ListItemText, MenuItem, Select, Tooltip } from '@mui/material';
import moment from 'moment';
import { FC } from 'react';

interface SelectNotEarlierThanProps {
  value: number | null;
  dueDate?: string;
  limit?: number;
  verbose?: boolean;
  centered?: boolean;
  onChange: (date: number | null) => void;
}

const genDays = (limit) => Array.from({ length: limit }, (_, i) => i);

export const SelectNotEarlierThan: FC<SelectNotEarlierThanProps> = ({
  value = null,
  limit = 31,
  dueDate,
  centered = false,
  verbose = false,
  onChange,
}) => {
  let days = genDays(limit);
  let todayDayOffset = Number.MAX_SAFE_INTEGER;
  if (dueDate) {
    todayDayOffset = moment(dueDate).diff(moment(), 'days') + 1;
    const diffDays = value ? Math.max(todayDayOffset, value) : todayDayOffset;
    days = days.slice(0, diffDays + 1);
  }

  const handleOnChange = (e) => {
    const val = e.target.value ?? null;
    onChange(val === '__CLEAR__' ? null : val);
  };
  const formatDay = (day, short = false) => {
    const d =
      day === todayDayOffset ? 'Today' : day === 1 ? '1 Day Earlier' : day ? `${day} Days Earlier` : 'On Due date';
    if (!dueDate) return d;
    const m = moment(dueDate).subtract(day, 'days').format('MMM D');
    return short ? m : `${d} (${m})`;
  };
  const renderValue = (value: number | string) => {
    const verbosePrefix = verbose ? 'Not earlier than ' : '';
    const text = (value === '' && 'Not earlier than') || `${verbosePrefix}${formatDay(value, true)}`;
    return (
      <Tooltip
        title="This allows you to define a period before the due date when it's ok to make scheduling suggestions."
        placement="top"
        disableInteractive
      >
        <ListItemButton sx={{ padding: '2px', textAlign: centered ? 'center' : 'left' }}>
          <ListItemText>{text}</ListItemText>
        </ListItemButton>
      </Tooltip>
    );
  };

  return (
    <Select
      variant="outlined"
      color="primary"
      sx={(theme) => ({
        height: '34px',
        borderRadius: '8px',
        color: theme ? theme.palette.primary.main : 'inherit',
        border: '1px solid',
        borderColor: 'rgba(80,150,190,0.5)',
        '&:hover': { borderColor: 'rgba(80,150,190,1)' },
        svg: { color: theme ? theme.palette.primary.main : 'inherit' },
        fieldset: { border: 'none' },
        '.MuiSelect-select': { padding: '0 8px' },
        minWidth: '120px',
        width: '100%',
        padding: 0,
      })}
      value={value ?? ''}
      onChange={handleOnChange}
      displayEmpty
      renderValue={renderValue}
    >
      {value !== null && <MenuItem value="__CLEAR__">Clear</MenuItem>}
      {value !== null && <Divider />}
      {days.map((day) => (
        <MenuItem key={day} value={day}>
          {formatDay(day)}
        </MenuItem>
      ))}
    </Select>
  );
};

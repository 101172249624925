import moment from 'moment';
import { CalendarEvent } from 'shared';
import * as eventOps from 'operations/event';

export const useTaskEventActions = (
  event: CalendarEvent
  // , task: Task
) => {
  const timeNow = moment();
  const timeNowToSet = moment(timeNow).startOf('minute').toISOString();
  const { beginDate, endDate, allDay, taskCompleted = false } = event;
  const mbegin = moment(beginDate);
  const mend = moment(endDate);
  const duration = mend.diff(mbegin, 'minutes');

  // TODO check new duration >= 15 min

  const canSetEndTime =
    taskCompleted &&
    !allDay &&
    moment(mbegin).add(5, 'minutes').isBefore(timeNow) &&
    moment(mend).subtract(5, 'minutes').isAfter(timeNow);

  const canMoveTaskToNow = !taskCompleted && !allDay && moment(mbegin).add(5, 'minutes').isBefore(timeNow);

  return {
    onSetEndTimeToNow: canSetEndTime
      ? () =>
          eventOps.saveEvent({
            ...event,
            endDate: timeNowToSet,
          })
      : null,
    onMoveTaskToNow: canMoveTaskToNow
      ? () =>
          eventOps.saveEvent({
            ...event,
            beginDate: timeNowToSet,
            endDate: moment(timeNowToSet).add(duration, 'minutes'),
          })
      : null,
  };
};

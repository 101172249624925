import React, { FC } from 'react';
import { Box, Button, Tooltip, SvgIcon } from '@mui/material';
import {
  Event,
  Repeat,
  EventRepeat,
  SportsScore,
  History,
  ExpandCircleDown,
  HighlightOff,
  UnfoldLess,
} from '@mui/icons-material';
import { isOverdue, formatDueScheduledDate } from '.';
import { inSchedulingQueue, Task } from 'shared';
import { theme } from '../../theme';
import moment from 'moment';
import { SmartSchedulingIcon, InSchedulingQueueIcon } from '../../components/CustomIcons';
import { useSelector } from 'react-redux';
import { ReduxState } from 'reducers';
import { useUserPrefs } from 'common/useUserPrefs';

const LONG_PRESS_DURATION = 1000;
const LONG_HOVER_DURATION = 500;

interface TaskScheduleWidgetProps {
  task: Task;
  listColor: string;
  onClick: () => void;
  onLongPress: () => void;
  onLongHover: () => void;
  onHoverEnd: () => void;
  showSuggestion?: boolean;
}

export const TaskScheduleWidget: FC<TaskScheduleWidgetProps> = ({
  task,
  listColor,
  onClick,
  onLongHover,
  onHoverEnd,
  onLongPress,
  showSuggestion = false,
}) => {
  const colorFaded = (theme.palette as any).faded.main;
  const colorDanger = (theme.palette as any).danger.main;

  const refLongPressHandled = React.useRef<boolean>(false);
  const refPressTimer = React.useRef<number | null>();
  const refHoverTimer = React.useRef<number | null>(null);

  const handleClick = () => {
    if (refLongPressHandled.current) {
      console.log('Click event after long press. Ignore');
      return;
    }
    if (refPressTimer.current) {
      clearTimeout(refPressTimer.current);
      refPressTimer.current = null;
    }
    if (refHoverTimer.current) {
      clearTimeout(refHoverTimer.current);
      refHoverTimer.current = null;
    }
    onClick();
  };

  let content,
    color = colorFaded,
    tooltip,
    icon;
  let taskOverdue = isOverdue(task) || false;
  let pastDueDate = moment(task.dueDate).isBefore(moment()) || false; // Today is considered past due
  const listsObj = useSelector<ReduxState, ReduxState['tasks']['listsObj']>((state) => state.tasks.listsObj);
  // const suggestionEvent = useSelector<ReduxState, ReduxState['calendar']['suggestionEvents']>(
  //   (state) => state.calendar.suggestionEvents[0]
  // ); // Optional - Used in longPressHint to display the suggested start time in the tooltip
  const longPressHint = (
    <>
      <br />
      Long-press to Schedule
      {/* <br />
      {suggestionEvent && suggestionEvent.start ? moment(suggestionEvent.start).format('ddd (Do), h:mm A') : '...'} */}
    </>
  );

  const prefs = useUserPrefs();
  const ctx = {
    momenttz: moment,
    prefs,
  };
  let inQueue = inSchedulingQueue(ctx, task, listsObj) || false;

  // States Legend
  // List color indicates that a task is either scheduled or is in the scheduling queue
  // Icons indicate whether a task is recurring or in scheduling queue

  if ((task.eventId || task.nextInstEventId) && task.eventBeginDate) {
    // Scheduled - colored date (hidden by default)
    // Overdue - red date with icon
    content = formatDueScheduledDate(task.eventBeginDate);
    color = listColor;
    tooltip = 'Scheduled';
    if (taskOverdue) {
      color = colorDanger;
      tooltip = (
        <span>
          Overdue
          {longPressHint}
        </span>
      );
      icon = <History color="inherit" />;
    }

    // Scheduled Recurrence - colored repeating event icon with date and tooltip
    // Overdue Recurrence - red repeating event icon with date and tooltip
    if (task.recurringEventIds) {
      icon = <EventRepeat color="inherit" />;
      tooltip = `Recurrence Scheduled for ${content}`;
    }
  } else if (task.dueDate) {
    // Due Date - gray date
    // Past Due Date - red date with icon
    content = formatDueScheduledDate(task.dueDate);
    color = colorFaded;
    tooltip = <span>Due Date{longPressHint}</span>;
    if (pastDueDate) {
      color = colorDanger;
      tooltip = <span>Past Due Date{longPressHint}</span>;
      icon = <SportsScore color="inherit" />;
    }

    // Flexible Recurrence - orange (inherit) repeat icon with date and recurrence rule in tooltip
    if (task.recurrence) {
      icon = <Repeat color="inherit" />;
      tooltip = (
        <span>
          Recurring {task.recurrence}
          {longPressHint}
        </span>
      );
      // Flexible Recurrence in Queue - colored trevor icon with date and recurrence rule in tooltip
      // TODO: Handle overdue flexible recurrences if needed. They seem to be pastDueDate true by default, as they appear on the day of the recurring due date.
      if (inQueue) {
        color = listColor;
        tooltip = (
          <span>
            Recurring {task.recurrence} | In Scheduling Queue{longPressHint}
          </span>
        );
      }
    }

    // Due Date in Queue - colored trevor icon with date
    // Past Due in Queue - orange trevor icon with date
    if (inQueue && !task.recurrence) {
      icon = <SmartSchedulingIcon color="inherit" size="16px" />;
      color = pastDueDate ? theme.palette.warning.main : listColor;
      tooltip = <span>Due Date | In Scheduling Queue{longPressHint}</span>;
    }
  } else {
    // Default - gray calendar icon
    // Default in Queue - colored trevor icon
    color = inQueue ? listColor : colorFaded;
    content = inQueue ? <SmartSchedulingIcon color={color} /> : <Event color="inherit" />;
    if (inQueue) {
      tooltip = (
        <span>
          In Scheduling Queue
          {longPressHint}
        </span>
      );
    } else {
      // tooltip = `Long-press to Schedule at [x]\n or Click for Scheduling Options`;
      tooltip = (
        <span>
          Show Scheduling Options
          {longPressHint}
        </span>
      );
    }
  }

  if (showSuggestion && !taskOverdue && !pastDueDate) {
    content = <UnfoldLess />;
    tooltip = 'Hide Scheduling Options';
  }

  return (
    <Box sx={{ maxWidth: '150px' }}>
      <Tooltip title={tooltip} placement="top" disableInteractive>
        <Button
          className="task-schedule-widget"
          onClick={handleClick}
          sx={{
            fontSize: '13px',
            minWidth: '40px',
            color, // does not accept mui color values like 'danger'. Button color prop does not accept rgb values.
            whiteSpace: 'nowrap',
            textTransform: 'none',
            '.MuiButton-startIcon': {
              marginRight: '3px',
              svg: { fontSize: '18px' },
            },
          }}
          startIcon={icon ? icon : ''}
          onMouseEnter={(e) => {
            console.log('Mouse Enter');
            refHoverTimer.current = window.setTimeout(() => {
              refHoverTimer.current = null;
              onLongHover();
            }, LONG_HOVER_DURATION);
          }}
          onMouseLeave={(e) => {
            console.log('Mouse Leave');
            if (refHoverTimer.current) {
              clearTimeout(refHoverTimer.current);
              refHoverTimer.current = null;
            }
            onHoverEnd();
          }}
          onMouseDown={(e) => {
            console.log('Mouse Down');
            refPressTimer.current = window.setTimeout(() => {
              refPressTimer.current = null;
              onLongPress();
              refLongPressHandled.current = true;

              // it's needed to prevent bad effects in case of window.confirm() called in onLongPress handler.
              // TODO better - like pass event to onLongPress or not call window.confirm() in onLongPress
              console.log('Simulate Mouse Up');
              const clickEvent = new window.Event('mouseup', { bubbles: true, cancelable: true });
              e.target.dispatchEvent(clickEvent);
            }, LONG_PRESS_DURATION);
          }}
          onMouseUp={() => {
            console.log('Mouse Up');
            if (refPressTimer.current) {
              clearTimeout(refPressTimer.current);
              refPressTimer.current = null;
              // console.log('Short Press');
            }
          }}
        >
          {content}
        </Button>
      </Tooltip>
    </Box>
  );
};

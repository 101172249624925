import React, { FC } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import moment from 'moment';
import { nanoid } from 'nanoid';
import { getVisibleEvents } from 'reducers/calendar';
import * as actions from 'actions';
import { Task, enhanceTaskWithUserScheduleConfig, generateSuggestionsForTasks } from 'shared';
import { ReduxState } from 'reducers';
import { useCancellableEffect } from 'common/useCancellableEffect';
import { getCachedTaskPredictions } from 'common/firebase';

export const AutoScheduler: FC<{ tasks: Task[]; limit?: number; overdueMode?: boolean }> = React.memo(
  ({ tasks, limit = 99, overdueMode }) => {
    const { rawDatesRange, prefs, events, listsObj } = useSelector((state: ReduxState) => {
      return {
        rawDatesRange: state.ui.viewDatesRange,
        prefs: state.account?.user?.prefs,
        events: getVisibleEvents(state),
        listsObj: state.tasks.listsObj,
      };
    }, shallowEqual);
    const dispatch = useDispatch();

    const shallowEventsJSON = JSON.stringify(
      events?.map(({ allDay, beginDate, endDate, isFree = false }) => ({ allDay, beginDate, endDate, isFree }))
    );

    useCancellableEffect(
      async (cancelHolder) => {
        const shallowEvents = JSON.parse(shallowEventsJSON);

        const timeNow = moment();
        const start = moment(rawDatesRange.start);
        const end = moment(rawDatesRange.end);

        if (end.isBefore(timeNow, 'date')) return; // don't generate suggestions for past dates (e.g. when switching to week view)

        // consider only upcoming period
        const fromDate = start.isBefore(timeNow, 'date') ? timeNow : start;

        const MAX_PERIOD_DAYS = 7;

        let toDate = moment(end);
        if (toDate.diff(fromDate, 'days') > MAX_PERIOD_DAYS) {
          toDate = moment(fromDate).add(MAX_PERIOD_DAYS, 'days');
        }

        const suggestions = await generateSuggestionsForTasks({
          ctx: {
            momenttz: moment,
            prefs,
          },
          tasks: tasks.map((task) => enhanceTaskWithUserScheduleConfig(task, prefs.user_schedule, listsObj)),
          fromDate,
          toDate,
          events: shallowEvents,
          strategy: 'predicted',
          limit,
          getCachedTaskPredictions,
        });

        const suggestionEvents = suggestions.map((suggestion) => {
          const { task, beginDate, duration, allDay } = suggestion;
          let start = allDay ? moment(beginDate).format('YYYY-MM-DD') : moment.utc(beginDate).format();
          let end = allDay
            ? moment(beginDate).add(duration, 'm').format('YYYY-MM-DD')
            : moment(beginDate).add(duration, 'm').utc().format();

          const listColor = listsObj[task.listId]?.color || '';

          if (overdueMode) {
            return {
              id: nanoid(),
              title: task.title,
              start,
              end,
              allDay,
              className: 'suggestion-event suggestion-event-overdue',
              editable: false,
              overdueTask: task,
            };
          }

          return {
            title: task.title,
            start,
            end,
            allDay,
            className: 'suggestion-event',
            editable: false,
            suggestionTask: task,
            suggestion,
            backgroundColor: listColor,
            borderColor: listColor,
            textColor: listColor,
          };
        });

        if (cancelHolder.isCancelled) return;

        dispatch(actions.setSuggestionEvents(suggestionEvents, true, overdueMode));

        return () => {
          dispatch(actions.compareAndClearSuggestionEvents(suggestionEvents));
        };
      },
      [prefs, dispatch, shallowEventsJSON, rawDatesRange.end, rawDatesRange.start, tasks, listsObj, limit, overdueMode],
      1200
    );

    return null;
  }
);

import { ajax_delete } from '../api/util';
import { generateEventId, generateNewEventDescription, logError } from 'common/utils';
import * as actions from '../actions';
import { fbOps } from '../common/firebase';
import { getDatabase, ref, onValue } from 'firebase/database';
import { completeTask, uncompleteTask, removeTaskById } from './task';
import { Notification } from '../components';
import Analytics from '../analytics.jsx';
import { generateTempId } from 'common/utils';
import { store, getUid } from './index';
import { CalendarEvent, SuggestionEvent, Task, normalizeKey } from 'shared';
import { CalendarEventInput } from 'types';
import { fetchNextRecurringEventUncompletedInstances, setCalendarEventRecurrence } from '../api/calendar';
import { clearDueDate } from 'api/todoist';
import { showModalErrorPerformingTaskEventOperation } from 'modules/tasks/showModalErrorPerformingTaskOperation';
import moment from 'moment';

export const saveEvent = (event, hintOperation = null) => {
  const {
    id,
    extraLongId,
    title,
    calendarId,
    beginDate,
    endDate,
    allDay,
    reminders,
    taskId,
    recurringEventMatchingTaskId,
  } = event;
  if (!calendarId) {
    return fbOps
      .getEventById(getUid(), id)
      .then((dbEvent) => saveEvent({ ...dbEvent, ...event }, hintOperation))
      .catch((err) => {
        logError(err, { hint: 'Error fetching eventById' });
        alert('Sorry, could not perform this operation');
      });
  }
  store().dispatch(
    actions.updateEventRequest(
      id,
      title,
      calendarId,
      beginDate,
      endDate,
      allDay,
      reminders,
      taskId,
      hintOperation,
      extraLongId,
      recurringEventMatchingTaskId
    )
  );
};

export const createNewEvent = (event: CalendarEventInput) => {
  console.log('createNewEvent', event);
  const { suggestId = generateEventId(), title, taskId, taskCompleted, beginDate, endDate, allDay } = event;

  // const task = store().getState().tasks.tasksObj[taskId];
  // const taskList = store().getState().tasks.listsObj[task?.listId || event.taskListId];
  // temp take precedence for list associated calendar id over event.calendarId
  // const calendarId =
  //   taskList?.associatedCalendarId || event.calendarId || store().getState().account.user.defaultCalendarId;

  store().dispatch(
    actions.addEventRequest(
      title,
      // calendarId,
      taskId,
      taskCompleted,
      beginDate,
      endDate,
      allDay,
      suggestId,
      generateNewEventDescription(suggestId)
    )
  );
};

export const setRecurrence = (event: CalendarEvent, recurrence: string | null, explicitTaskId?: string) => {
  console.log('GAGA setRecurrence', event, recurrence);
  const { id, recurringEventId, calendarId, taskId } = event;
  return setCalendarEventRecurrence({
    calendarId,
    eventId: id,
    recurrence,
    recurringEventId,
    taskId: taskId || explicitTaskId,
  });
};

export const createNewAssociatedEvent = (event: CalendarEventInput) => {
  if (event.taskId) {
    createNewEvent(event);
  } else {
    const { suggestId = generateEventId(), title, taskListId, beginDate, endDate, allDay } = event;

    const listId = taskListId || store().getState().tasks.defaultListId;
    // const taskList = store().getState().tasks.listsObj[listId];
    // console.log('createNewAssociatedEvent', event, taskList, listId);
    // // temp take precedence for list associated calendar id over event.calendarId
    // const calendarId =
    //   taskList?.associatedCalendarId || event.calendarId || store().getState().account.user.defaultCalendarId;

    store().dispatch(
      actions.addEventWithTaskRequest(
        // getUid(),
        title,
        // calendarId,
        listId,
        suggestId,
        beginDate,
        endDate,
        allDay,
        generateTempId(),
        generateNewEventDescription(suggestId)
      )
    );
  }
};

export const scheduleOrRescheduleTask = async (
  task: Task,
  suggestion: SuggestionEvent,
  default_task_duration: number,
  analyticsHint = ''
) => {
  console.log('scheduleOrRescheduleTask', task, suggestion);

  const { eventId, nextInstEventId, eventBeginDate } = task;
  const { start, end, allDay } = suggestion;
  const eventData = {
    beginDate: moment(start).toISOString(),
    endDate: moment(end).toISOString(),
    allDay,
  };
  if (allDay) {
    eventData.beginDate = moment(eventData.beginDate).format('YYYY-MM-DD');
    eventData.endDate = moment(eventData.endDate).format('YYYY-MM-DD');
  }

  // Re-schedule
  if (eventId || (nextInstEventId && eventBeginDate)) {
    const oldEvent = await getEventById(nextInstEventId || eventId);
    if (!oldEvent) {
      return showModalErrorPerformingTaskEventOperation({
        task,
        operation: 'scheduling',
      });
    }

    if (
      !window.confirm(
        'Task scheduled for: ' +
          moment(eventBeginDate).format(allDay ? 'MMMM Do' : 'MMMM Do, HH:mm a') +
          ', would you like to reschedule it?'
      )
    ) {
      return;
    }

    const id = eventId || task?.nextInstEventId;

    saveEvent({
      id,
      ...eventData,
    });

    Analytics.event({
      category: 'Scheduling',
      action: 'Rescheduled Task from Inbox' + ' ' + analyticsHint,
    });
    return;
  }

  // Schedule
  const newEvent = {
    suggestId: generateEventId(),
    title: task.title,
    taskId: task.id,
    ...eventData,
  };

  createNewAssociatedEvent(newEvent);

  Analytics.event({
    category: 'Scheduling',
    action: 'Scheduled Task from Inbox' + ' ' + analyticsHint,
  });
};

export const renameEventWithAssociatedTask = (event) => {
  const { id, title, calendarId, taskId } = event;
  if (!calendarId) {
    return fbOps
      .getEventById(getUid(), id)
      .then((dbEvent) => renameEventWithAssociatedTask({ ...dbEvent, ...event }))
      .catch((err) => {
        logError(err, { hint: 'Error fetching eventById' });
        alert('Sorry, could not perform this operation');
      });
  }
  store().dispatch(actions.renameEventRequest(id, title, calendarId, taskId));
};

const getNextRecurringInstanceIfNeeded = async (event: CalendarEvent, task: Task) => {
  console.log('getNextRecurringInstanceIfNeeded', { event, task }, task.eventBeginDate, event.beginDate);
  if (task.eventBeginDate > event.beginDate && task.nextInstEventId) {
    console.log('Recurring event is in the past, ignore and return current values');
    return {
      nextInstEventId: task.nextInstEventId,
      eventBeginDate: task.eventBeginDate,
      eventEndDate: task.eventEndDate,
    };
  }
  let { instance } = await fetchNextRecurringEventUncompletedInstances(
    event.calendarId,
    event.recurringEventId,
    event.beginDate < task.eventBeginDate ? event.beginDate : task.eventBeginDate
  );
  console.log('Recurring event next uncompleted instance', instance);
  if (!instance) {
    console.log('No next recurring instance found');
    return;
  }
  return {
    nextInstEventId: instance.id,
    eventBeginDate: instance.beginDate,
    eventEndDate: instance.endDate,
  };
};

export const getAndSaveNExtRecurringInstanceIfNeeded = async (event: CalendarEvent, task: Task) => {
  let nextRecurringInstance = await getNextRecurringInstanceIfNeeded(event, task);
  if (nextRecurringInstance) {
    console.log('Set next recurring instance ', nextRecurringInstance);
    return fbOps.setTaskNextInst(task.id, nextRecurringInstance);
  }
  return null;
};

const getRecurringEventIdFromTask = (task: Task) => {
  if (task.recurringEventIds) {
    const ids = Object.keys(task.recurringEventIds);
    if (ids.length === 1) {
      return ids[0];
    }
    if (!task.nextInstEventId) {
      console.log('Task has multiple recurring events, but no nextInstEventId', task);
      return null;
    }
    const base = task.nextInstEventId.split('_')[0];
    const matchingId = ids.find((id) => id.startsWith(base));
    if (!matchingId) {
      console.log('Task has multiple recurring events, but no matchingId', task, base, ids);
      return null;
    }
    return matchingId;
  }
  return null;
};

export const eventComplete = (
  event: CalendarEvent,
  recurringEventMatchingTaskId?: string,
  calledFromCompleteTask = false
) => {
  console.log('eventComplete', event, { recurringEventMatchingTaskId, calledFromCompleteTask });
  let taskId = event.taskId || recurringEventMatchingTaskId;
  let task = store().getState().tasks.tasksObj[taskId];
  console.log('associated task', task);

  if (recurringEventMatchingTaskId && !event.recurringEventId) {
    console.log('Task has recurringEventMatchingTaskId, but event has no recurringEventId', event, task);
    event.recurringEventId = getRecurringEventIdFromTask(task);
    console.log('Guessed recurringEventId', event.recurringEventId);
  }

  if (event.recurringEventId) {
    return fbOps
      .completeRecurringEvent(getUid(), event.id, taskId)
      .then(() => getAndSaveNExtRecurringInstanceIfNeeded(event, task))
      .then((inst) => {
        if (!inst) {
          console.log('No next recurring instance found. Complete task!');
          Analytics.event({
            category: 'Task',
            action: 'Completed Recurring Task Fully',
          });
          store().dispatch(actions.completeTask(taskId, null)); //) task.eventId, task.todoist?.due));
          // TODO we should do it anyway to complete Todoist instances
        }
      })
      .then(() => {
        Notification.show('Recurring task completed.', 3000);
        Analytics.event({ category: 'Event', action: 'Completed Recurring Task/Event' });
      })
      .catch((err) => {
        Notification.showError('Error completing recurring task.', 3000);
        logError(err, { hint: 'Error completing recurring event' });
      });
  }

  if (!calledFromCompleteTask) {
    // it's to prevent infinite loop
    return completeTask(task);
  }
};

export const eventCompleteById = async (eventId, recurringEventMatchingTaskId, calledFromCompleteTask = false) => {
  let event = store().getState().calendar.eventsObj[eventId];
  if (!event) {
    event = await fbOps.getEventById(getUid(), eventId);
  }
  if (!event) {
    throw new Error('Event not found');
  }
  return eventComplete(event, recurringEventMatchingTaskId, calledFromCompleteTask);
};

export const eventUncomplete = (event) => {
  console.log('eventUncomplete', event);
  let taskId = event.taskId;
  let task = store().getState().tasks.tasksObj[taskId];
  // console.log('Task', task)

  if (event.recurringEventId) {
    return fbOps
      .uncompleteRecurringEvent(getUid(), event.id, taskId, {
        eventBeginDate: event.beginDate,
        eventEndDate: event.endDate,
        nextInstEventId: event.id,
      })
      .then(() => {
        Notification.show('Recurring task uncompleted.', 3000);
        Analytics.event({ category: 'Event', action: 'Uncompleted Recurring Task/Event' });
      })
      .catch((err) => {
        Notification.showError('Error uncompleting recurring task.', 3000);
        logError(err, { hint: 'Error uncompleting recurring event' });
      });
  }

  uncompleteTask(task);
};

export const eventPostponeById = (eventId, associatedTaskId) => {
  console.log('eventPostponeById', eventId);
  let calendarId;
  const removeEvent = () => eventRemove({ id: eventId, calendarId: calendarId, taskId: associatedTaskId });

  const event = store().getState().calendar.eventsObj[eventId];
  if (event) {
    calendarId = event.calendarId;
    removeEvent();
  } else {
    const evRef = ref(getDatabase(), 'calendar_events/' + getUid() + '/' + normalizeKey(eventId));
    onValue(
      evRef,
      (snap) => {
        let eventStruct = snap.val();
        if (!eventStruct) {
          // dangling event association - just silently remove association from task
          return fbOps.breakAssociationByTaskId(getUid(), associatedTaskId);
        }
        calendarId = eventStruct.calendarId;
        removeEvent();
      },
      {
        onlyOnce: true,
      }
    );
  }
};

export const eventPostpone = (event) => {
  console.log('eventPostpone', event);
  store().dispatch(actions.removeEventFromUI(event.id));
  return ajax_delete('/api/event/' + encodeURIComponent(event.id))
    .then(() => {
      Notification.show("Task postponed. Don't forget to reschedule.", 3000);
      Analytics.event({ category: 'Scheduling', action: 'Postponed Task' });
    })
    .catch((err) => {
      logError(err, ['Postpone event', event]);
      store().dispatch(actions.putEventToUI(event));
      Notification.showError('Failed to postpone Task. Please try again.');
    });
};

export const eventPartialComplete = (eventIsh: string | CalendarEvent) => {
  console.log('eventPartialComplete', eventIsh);
  let eventId = typeof eventIsh === 'object' ? eventIsh.id : eventIsh;
  return fbOps
    .partialComplete(getUid(), eventId)
    .then(() => Notification.show('Task postponed, but also saved in calendar.', 3000))
    .then(() => Analytics.event({ category: 'Task', action: 'Partially Completed Task' }))
    .then(() => {
      const event = typeof eventIsh === 'object' ? eventIsh : store().getState().calendar.eventsObj[eventIsh];
      if (!event || !event.taskId) {
        return;
      }
      const task = store().getState().tasks.tasksObj[event.taskId];
      if (!task) {
        return;
      }
      if (task.todoist?.due?.date && !task.todoist?.due.is_recurring) {
        const todoistAccessToken = store().getState().account.user?.accounts?.todoist?.accessToken;
        const syncDateToTodoist = store().getState().account.user?.accounts?.todoist?.syncDateToTodoist;
        if (!todoistAccessToken || !syncDateToTodoist) {
          return;
        }
        return clearDueDate(todoistAccessToken, task.todoist.id).catch((err) => {
          logError(err, { hint: 'Failed to clear Todoist due date after partial complete' });
        });
      }

      store().dispatch(actions.setShowSuggestionsForTaskId(task.id, true));
    })
    .catch((err) => {
      logError(err, ['Partial complete event', eventIsh]);
      Notification.showError('Failed to partially complete Task. Please try again.');
    });
};

export const eventRemove = (
  event,
  { recurringEventMatchingTaskId, allInstances }: { recurringEventMatchingTaskId?: string; allInstances?: boolean } = {}
) => {
  console.log('eventRemove', event);
  const queryParams = new URLSearchParams();
  if (allInstances) {
    queryParams.append('allInstances', 'true');
  }
  if (recurringEventMatchingTaskId) {
    queryParams.append('recurringEventMatchingTaskId', recurringEventMatchingTaskId);
  }

  return ajax_delete('/api/event/' + encodeURIComponent(event.id) + '?' + queryParams)
    .then(() => {
      Notification.show('Event removed successfully.', 3000);
      Analytics.event({ category: 'Event', action: 'Removed Event' });
    })
    .catch((err) => {
      logError(err, ['Remove event', event]);
      Notification.showError('Failed to remove event. Please try again.');
      throw err;
    });
};

export const removeEventWithAssociatedTask = async (event, recurringEventMatchingTaskId?: string) => {
  let taskId = event.taskId || recurringEventMatchingTaskId;
  try {
    // recurringEventMatchingTaskId is NOT used anymore, consider removing
    await eventRemove(event, recurringEventMatchingTaskId ? { recurringEventMatchingTaskId, allInstances: true } : {});
    Notification.show('Event removed successfully.', 3000);

    if (taskId) {
      await removeTaskById(taskId);
      Notification.show('Event + Task removed successfully.', 3000);
    }
  } catch (err) {
    // noop, handled somewere else
  }
};

export const removeEventInstance = async (event, recurringEventMatchingTaskId: string) => {
  try {
    await eventRemove(event, { recurringEventMatchingTaskId });

    Notification.show('Event  removed successfully.', 3000);
  } catch (err) {
    // noop, handled somewere else
  }
};

export const turnEventIntoTask = async (event) => {
  console.log('turnEventIntoTask', event);
  if (event.taskId) {
    console.log('Event already has a task associated with it. Not turning into task.');
    return;
  }

  store().dispatch(actions.turnEventIntoTask(event));
};

export const getEventById = (id) => fbOps.getEventById(getUid(), id);

import React, { useState, useRef, FC, useEffect } from 'react';
import moment from 'moment';
import Analytics from '../analytics.jsx';
import { Note } from './Note.jsx';
import DateTimePicker from './DateTimePicker';
import EventTimer from './EventTimer.jsx';
import { isExperimental, detectOrigin, linkToEvent, isDateOverdue } from 'common/utils';
import { CalendarEvent, detectProvider, resolveTaskTitleWithParents } from 'shared';
import { ListNameWithIcon } from 'components';
import { getVisibleTasksWithLists, getRecurringEventIdsToTaskIds, getRecurringTaskEventsCount } from 'reducers/tasks';
import { getAccountEmailByCalendarId } from 'reducers/calendar';
import * as eventOps from '../operations/event';
import * as plannerOps from '../operations/schedule';
import { saveTaskNote } from '../operations/sharing';
import { TaskActionPlan } from './OpenAI.jsx';
import { styled } from '@mui/system';
import {
  Divider,
  Button,
  IconButton,
  Box,
  MenuItem,
  Menu,
  Drawer,
  Dialog,
  ListItemText,
  ListSubheader,
  TextField,
  DialogTitle,
  Stack,
  Select,
  DialogContent,
  Tooltip,
} from '@mui/material';

import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import NavigationCheck from '@mui/icons-material/Check';
import PlaylistAddCheck from '@mui/icons-material/PlaylistAddCheck';
import IndeterminateCheckBox from '@mui/icons-material/IndeterminateCheckBox';
import ActionDateRange from '@mui/icons-material/DateRange';
import EventBusy from '@mui/icons-material/EventBusy';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import ExternalIcon from '@mui/icons-material/OpenInNew';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import TimerIcon from '@mui/icons-material/Timer';
import EditIcon from '@mui/icons-material/Edit';
import LoopIcon from '@mui/icons-material/Loop';
import CalendarToday from '@mui/icons-material/CalendarToday';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import PremiumIcon from '@mui/icons-material/WorkspacePremium';
import { SportsScore } from '@mui/icons-material';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';

import MoveToListMenuItem from './MoveToListMenuItem';
import { OpenInTodoist } from './OpenInTodoist.jsx';
import { ExpandMore } from '@mui/icons-material';
import { RemoveWithConfirmListItem } from './RemoveWithConfirmListItem.jsx';
import { TextFieldForm } from './TextFieldForm.jsx';
import { getReadonlyCalendarsIds } from 'reducers/calendar';
import { OpenInCalendar } from './OpenInCalendar.jsx';
import { RecurringEventDetails } from './RecurringEventDetails';
import { PremiumModalDock } from '../premium';
import { CenteredSpinner } from './CenteredSpinner';
import { formatDueScheduledDate } from 'modules/tasks/index.jsx';
// import { SelectDueDateWidget } from 'modules/tasks/SelectDueDateWidget';
import Markdown from 'react-markdown';
import { theme } from 'theme';
import { useSelector } from 'react-redux';
import { ReduxState } from 'reducers/index';
import { useTaskEventActions } from 'common/useTaskEventActions';

const optionToOperation = (option, event, recurringEventMatchingTaskId) => {
  switch (option) {
    case 'complete':
      return eventOps.eventComplete(event, recurringEventMatchingTaskId);
    case 'uncomplete':
      return eventOps.eventUncomplete(event);
    case 'partialComplete':
      return eventOps.eventPartialComplete(event);
    case 'postpone':
      return eventOps.eventPostpone(event);
    case 'remove':
      // we don't want to remove recurring event from here, only single instance at most
      // and if regular event, it will be removed with associated task
      return recurringEventMatchingTaskId
        ? eventOps.removeEventInstance(event, recurringEventMatchingTaskId)
        : eventOps.removeEventWithAssociatedTask(event);
    case 'associate_with_task':
      return eventOps.turnEventIntoTask(event);
    default:
    // noop
  }
};

const LockIcon = (moduleId) => {
  return (
    <Tooltip
      title="Pro feature. Up to 3 Recurring Scheduled Tasks available on the Free tier."
      onClick={() => PremiumModalDock.showUpgradeModule(moduleId)}
    >
      <PremiumIcon style={{ color: 'rgba(0, 0, 0, 0.33)', marginLeft: '10px' }} />
    </Tooltip>
  );
};

export const reminderDropdownValues = {
  0: 'At time of event',
  5: '5 minutes before',
  10: '10 minutes before',
  15: '15 minutes before',
  30: '30 minutes before',
  60: '1 hour before',
  120: '2 hours before',
  1440: '1 day before',
  2880: '2 days before',
};

export const ReminderDropDown = ({ minutes, onSetReminder }) => {
  return (
    <>
      <Select
        value={minutes ?? ''}
        variant="standard"
        displayEmpty
        renderValue={(val) => reminderDropdownValues[val] || 'Add reminder'}
        // onChange={(e) => onSetReminder(e.target.value)}
      >
        {Object.entries(reminderDropdownValues).map(([val, label]) => (
          <MenuItem
            key={val}
            value={val}
            // selected={+val === minutes}
            onClick={() => onSetReminder(val)}
          >
            {label}
          </MenuItem>
        ))}
        <Divider key="rmnd-div" />
        <MenuItem value="" onClick={() => onSetReminder(null)}>
          No reminder
        </MenuItem>
      </Select>
    </>
  );
};

const ReminderItem = ({ minutes, withCustom = false, onChange }) => {
  const ref = useRef();
  const [open, setOpen] = useState(false);
  const [showCustom, setShowCustom] = useState(false);

  const handleChange = (val) => {
    if (val !== undefined) onChange(val);
    setShowCustom(false);
    setOpen(false);
  };

  return (
    <>
      <DrawerButton
        variant="outlined"
        ref={ref}
        onClick={() => setOpen(true)}
        color={minutes !== null ? 'secondary' : undefined}
        sx={{
          backgroundColor: minutes !== null ? 'rgba(255, 140, 44, 0.06)' : 'rgba(99, 142, 175,0.1)',
          color: minutes !== null ? 'rgba(255, 140, 44, 1)' : 'rgba(99, 142, 175,1)',
        }}
        startIcon={<TimerIcon />}
        endIcon={<ArrowDropDown />}
        //  secondaryAction={withCustom ? <CustomReminderDialog setReminder={onChange} /> : undefined}
      >
        {minutes !== null
          ? reminderDropdownValues[minutes] || moment.duration(minutes, 'minutes').humanize() + ' before'
          : 'Add reminder'}
      </DrawerButton>
      <Menu
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={ref.current}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        {Object.entries(reminderDropdownValues).map(([val, label]) => (
          <MenuItem key={val} selected={+val === minutes} onClick={() => handleChange(val)}>
            {label}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem selected={minutes === null} onClick={() => handleChange(null)}>
          No reminder
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            setOpen(false);
            setShowCustom(true);
          }}
        >
          Custom
        </MenuItem>
      </Menu>
      {showCustom && <CustomReminderDialog onCloseWith={handleChange} />}
    </>
  );
};

const EditableTitle = ({ event, task, onSave }) => {
  const tasksObj = useSelector<ReduxState, ReduxState['tasks']['tasksObj']>((state) => state.tasks.tasksObj);
  const taskTitleParentPath = task ? resolveTaskTitleWithParents(task, tasksObj, true) : '';
  const value = event.title; //task?.title || event.title;
  const [edit, setEdit] = useState(false);
  return edit ? (
    <TextFieldForm
      multiline
      initialValue={value}
      onSave={(value) => {
        onSave(value);
        setEdit(false);
      }}
      onCancel={() => setEdit(false)}
    />
  ) : (
    <span
      style={{
        fontSize: '1.3rem',
        color: 'black',
        fontWeight: 300,
        cursor: 'pointer',
        // '&:hover': { textDecoration: 'underline' },
      }}
      onClick={() => setEdit(true)}
      // href="#"
    >
      {!!taskTitleParentPath && (
        <Markdown className="task-parent-path title-with-markdown">{taskTitleParentPath}</Markdown>
      )}
      <Markdown className="title-with-markdown">{value}</Markdown>
    </span>
  );
};

const FlexBox = ({ children, style = {}, ...props }) => {
  return (
    <Box
      {...props}
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        alignItems: 'center',
        // justifyContent: 'space-between',
        padding: '15px',
        ...style,
      }}
    >
      {children}
    </Box>
  );
};

export const DrawerButton = styled(Button)({
  borderRadius: '15px',
  backgroundColor: 'rgba(99, 142, 175,0.1)',
  color: 'rgb(99, 142, 175)', // theme.palette.darkBlue.main,
  border: 'none !important', // hover
});

const NewDivider = styled(Divider)({
  borderBottom: '1px solid rgba(125,160,190,0.2)',
  margin: '0 15px',
});

const experimentalMode = !isExperimental();

interface EventContextMenuProps {
  selectedEventId: string;
  onClose: () => void;
}
export const EventContextMenu: FC<EventContextMenuProps> = ({ selectedEventId, onClose }) => {
  const [open, setOpen] = useState(false);
  const [wideContent, setWideContent] = useState(false);

  const _event = useSelector<ReduxState, CalendarEvent>((state) => state.calendar.eventsObj[selectedEventId]);
  const refEvent = useRef(_event);
  const event = _event || refEvent.current || ({} as CalendarEvent);

  const recurrenceAnchor = useRef();
  const [recurrenceOptionsAnchorEl, setRecurrenceOptionsAnchorEl] = useState(null);
  const rescheduleAnchor = useRef();
  const [rescheduleOptionsAnchorEl, setRescheduleOptionsAnchorEl] = useState(null);

  const recurringEventIdsToTaskIds = useSelector(getRecurringEventIdsToTaskIds);
  const isPremium = useSelector<ReduxState, boolean>((state) => state.account.user.isPremium);
  const recurringEventsUsed = useSelector<ReduxState, number>((state) => getRecurringTaskEventsCount(state));
  const inProgress = useSelector<ReduxState, boolean>((state) => state.calendar.inProgressObj[selectedEventId]);
  const readonly = useSelector<ReduxState, boolean>((state) =>
    event ? getReadonlyCalendarsIds(state).has(event.calendarId) : false
  );
  const accountEmail = useSelector<ReduxState, string>((state) =>
    event ? getAccountEmailByCalendarId(state, event.calendarId) : ''
  );
  const tasks = useSelector(getVisibleTasksWithLists);
  const listsObj = useSelector<ReduxState, ReduxState['tasks']['listsObj']>((state) => state.tasks.listsObj);
  const calendarsObj = useSelector<ReduxState, ReduxState['calendar']['calendarsObj']>(
    (state) => state.calendar.calendarsObj
  );

  const closeDrawer = () => {
    setOpen(false);
    setTimeout(() => {
      onClose();
    }, 300);
  };

  const expandDrawer = (hint = null) => {
    setWideContent(hint ?? !wideContent);
    Analytics.event({ category: 'Event Menu', action: 'Toggle Drawer' });
  };

  useEffect(() => {
    setOpen(true);
    Analytics.event({ category: 'Event Menu', action: 'Opened Drawer' });
  }, []);

  const { onMoveTaskToNow, onSetEndTimeToNow } = useTaskEventActions(event);

  // componentWillMount() {
  //   const { event, eventId } = this.props;
  //   this.event = event;
  //   if (!event) {
  //     eventOps
  //       .getEventById(eventId)
  //       .then((event) => {
  //         if (!event) return Notification.showError('Event is not found');
  //         this.event = event;
  //         this.forceUpdate();
  //       })
  //       .catch((err) => {
  //         Notification.showError('Error loading event');
  //         console.log('Error loading event:', err);
  //       });
  //   }
  // }

  const handleClickAndClose = (option) => {
    const recurringEventMatchingTaskId = recurringEventIdsToTaskIds?.[event?.recurringEventId];
    optionToOperation(option, event, recurringEventMatchingTaskId);
    Analytics.event({ category: 'Event Menu', action: 'Clicked ' + option });
    closeDrawer();
  };

  const setReminder = (minutes, index) => {
    let overrides = event?.reminders?.overrides ? [...event.reminders.overrides] : [];
    console.log({ event, minutes, index, overrides });
    if (index === -1) index = overrides.length;
    if (minutes === null) {
      overrides.splice(index, 1);
    } else {
      overrides[index] = {
        method: 'popup',
        ...overrides[index],
        minutes: minutes,
      };
    }

    let newEvent = {
      ...event,
      reminders: {
        useDefault: !overrides.length,
        overrides: null,
      },
    };
    if (overrides.length) newEvent.reminders.overrides = overrides;

    eventOps.saveEvent(newEvent, 'reminder');
  };
  // Timer Suggestions could be used with basic ML, same as quick schedule suggestions

  console.log('EventContextMenu event', event);
  if (!event) return <span />;
  const taskId = event.taskId || recurringEventIdsToTaskIds[event.recurringEventId];
  const eventTask = tasks.find((task) => task.id === taskId);
  if (eventTask) console.log('EventContextMenu eventTask', eventTask);

  const completedTaskId = event.completedTaskId || event.completedRecurringTaskId;
  const partiallyCompletedTask = completedTaskId ? tasks.find((task) => task.id === completedTaskId) : null;

  const isRecurringEvent = !!event.recurringEventId;
  const isGoogleEvent = detectOrigin(event.calendarId) === 'google';
  // temp until support for others is implemented
  const cannotSetRecurrence = !isGoogleEvent;

  const calendarHtmlLink = linkToEvent(event, accountEmail);

  // const timeNow = moment();

  let associatedWithTask = event && taskId && !!eventTask; // Also checks if task's list is enabled
  let completed = associatedWithTask && event.taskCompleted;
  const hide = false,
    show = true;
  let attrs = {
    complete: associatedWithTask && !completed ? show : hide,
    uncomplete: completed && !completedTaskId ? show : hide,
    postpone: associatedWithTask && !partiallyCompletedTask ? show : hide,
    list: associatedWithTask || partiallyCompletedTask ? show : hide,
    note: associatedWithTask ? show : hide,
    partialComplete: false,
  };
  attrs.partialComplete = attrs.complete && !isRecurringEvent;

  let reschedOps = [
    'Today',
    'Tomorrow',
    'Weekend',
    'Next week',
    <Divider key="reshed-div" />,
    'In 2 weeks',
    'In a month',
    'In 3 months',
    'Some day',
  ].map((when) => {
    if (typeof when === 'object') return when;

    return (
      <MenuItem
        key={when}
        onClick={() => {
          plannerOps.scheduleEvent(event, when, eventTask);
          Analytics.event({ category: 'Scheduling', action: 'Scheduled Task from Quick Suggestions' });
          Analytics.event({ category: 'Event Menu', action: 'Clicked Quick Suggestion' });
          closeDrawer();
        }}
      >
        {when}
      </MenuItem>
    );
  });

  let recurrenceOps = [
    'Daily',
    'Weekly',
    'Monthly',
    'Yearly',

    ...(!!event.htmlLink
      ? [
          <Divider key="recurrence-div-custom" />,
          <MenuItem
            key="recurrence-custom"
            onClick={() => {
              if (!isPremium && recurringEventsUsed >= 3) {
                PremiumModalDock.showUpgradeModule('recurring');
                return;
              }
              window.open(calendarHtmlLink, '_blank');
            }}
          >
            <ListItemText>Custom</ListItemText>
            &nbsp; &nbsp;
            <ExternalIcon />
            {!isPremium && <LockIcon moduleId={'recurring'} />}
          </MenuItem>,
        ]
      : []),

    ...(!!event.recurringEventId ? [<Divider key="recurrence-div" />, 'Remove recurrence'] : []),
  ].map((when) => {
    if (typeof when === 'object') return when;

    return (
      <MenuItem
        key={when}
        onClick={() => {
          if (when === 'Remove recurrence') {
            eventOps.setRecurrence(event, null, taskId);
            Analytics.event({ category: 'Scheduling', action: 'Removed recurrence' });
          } else {
            if (!isPremium && recurringEventsUsed >= 3) {
              PremiumModalDock.showUpgradeModule('recurring');
              return;
            }
            eventOps.setRecurrence(event, when);
            Analytics.event({ category: 'Scheduling', action: 'Setting recurrence' });
          }
          closeDrawer();
        }}
      >
        <ListItemText>{when}</ListItemText>
        {!isPremium && when !== 'Remove recurrence' && <LockIcon moduleId={'recurring'} />}
      </MenuItem>
    );
  });

  if (associatedWithTask || partiallyCompletedTask) {
    var eventTaskList = listsObj[eventTask?.listId || partiallyCompletedTask?.listId];
  }

  const multipleRemindersSupported = detectProvider(event.id) === 'google';
  const singeReminderSupported = detectProvider(event.id) === 'microsoft';
  const maxRemindersCount = multipleRemindersSupported ? 5 : singeReminderSupported ? 1 : 0;

  const removeHint = !!event.recurringEventId ? 'this event' : associatedWithTask ? 'Task & Event' : 'Event';

  const isExtended = wideContent || !experimentalMode;

  let attributeField;
  if (attrs.list) {
    attributeField = (
      <Box sx={{ display: 'block', textAlign: 'right' }}>
        <MoveToListMenuItem
          task={eventTask || partiallyCompletedTask}
          // eventTaskList={eventTaskList}
          button={
            <DrawerButton
              variant="text"
              sx={{
                color: eventTaskList.color,
                background: 'none',
                border: '1px solid ' + eventTaskList.color + ' !important',
                height: '34px',
                lineHeight: '29px',
                textTransform: 'none',
                fontSize: '1em',
              }}
              endIcon={<ArrowDropDown />}
              // onClick={() => {
              //   onSelectList(eventTaskList.id);
              //   this.closeDrawer();
              //   Analytics.event({
              //     category: 'Event Menu',
              //     action: 'Clicked List',
              //   });
              // }}
            >
              <ListNameWithIcon list={eventTaskList} size={16} />
            </DrawerButton>
          }
        />
      </Box>
    );
  } else if (event.calendarId) {
    attributeField = (
      <DrawerButton
        disabled
        variant="outlined"
        startIcon={<CalendarToday />}
        // sx={{ color: event.color, height: '26px', lineHeight: '21px', paddingLeft: '0', textTransform: 'none' }}
      >
        {calendarsObj[event.calendarId]?.title ||
          Object.values(calendarsObj).find((cal) => cal.id === event.calendarId)?.title}
      </DrawerButton>
    );
  }

  const content = inProgress ? (
    <CenteredSpinner />
  ) : (
    <Box
      sx={{
        width: '100%',
        maxWidth: '100vw',
        overflowY: 'scroll',
        scrollbarGutter: 'stable',
        overflowX: 'hidden',
      }}
    >
      {/* <List disablePadding> */}
      <FlexBox style={{ paddingBottom: 0 }}>
        <EventTimer event={event} />
      </FlexBox>

      {/* not yet ready */}
      {/* <FlexBox style={{ paddingBottom: 0 }}>
        {!!onMoveTaskToNow && (
          <Button
            variant="outlined"
            onClick={() => {
              onMoveTaskToNow();
              Analytics.event({ category: 'Scheduling', action: 'Move Taskto Now' });
            }}
          >
            Move task to now
          </Button>
        )}
        {!!onSetEndTimeToNow && (
          <Button
            variant="outlined"
            onClick={() => {
              onSetEndTimeToNow();
              Analytics.event({ category: 'Scheduling', action: 'Changed Task EndDate to Now' });
            }}
          >
            Set End time to now
          </Button>
        )}
      </FlexBox> */}

      <FlexBox style={{ paddingBottom: 0, marginLeft: '15px' }}>
        <EditableTitle
          // value={event.title}
          event={event}
          task={eventTask}
          onSave={(val) => {
            eventOps.renameEventWithAssociatedTask({
              ...event,
              title: val,
            });
            Analytics.event({
              category: 'Event Menu',
              action: 'Renamed Event',
            });
          }}
        />

        <FlexBox style={{ gap: '7px', width: '100%', paddingTop: '0', marginTop: '-5px', paddingLeft: 0 }}>
          {attributeField}
          {!!(eventTask || partiallyCompletedTask)?.todoist && (
            <div
              style={{
                float: 'left',
                height: '100%',
                lineHeight: '24px',
                paddingLeft: 16,
              }}
            >
              <OpenInTodoist task={eventTask || partiallyCompletedTask} />
            </div>
          )}
          {!!calendarHtmlLink && (
            <div
              style={{
                float: 'left',
                height: '100%',
                lineHeight: '24px',
                paddingLeft: 16,
              }}
            >
              <OpenInCalendar htmlLink={calendarHtmlLink} />
            </div>
          )}
        </FlexBox>

        {event.isFree && (
          <FlexBox style={{ marginTop: '-12px', padding: 0, opacity: 0.5 }}>
            <WorkOutlineIcon /> Free
          </FlexBox>
        )}
      </FlexBox>
      <FlexBox>
        {attrs.complete && (
          <DrawerButton
            variant="outlined"
            onClick={() => handleClickAndClose('complete')}
            startIcon={<NavigationCheck />}
          >
            Complete
          </DrawerButton>
        )}
        {attrs.partialComplete && (
          <Tooltip title="Saves the Calendar Event and returns the Task to the Task Hub for rescheduling.">
            <DrawerButton
              variant="outlined"
              onClick={() => handleClickAndClose('partialComplete')}
              startIcon={<PlaylistAddCheck />}
            >
              Unfinished
            </DrawerButton>
          </Tooltip>
        )}
        {!associatedWithTask && !completedTaskId && (
          <DrawerButton
            variant="outlined"
            onClick={() => {
              if (!isPremium && event.recurringEventId && recurringEventsUsed >= 3) {
                PremiumModalDock.showUpgradeModule('recurring');
                return;
              }
              handleClickAndClose('associate_with_task');
            }}
            startIcon={<AutoFixHighIcon />}
          >
            Turn into Task
          </DrawerButton>
        )}
        {attrs.uncomplete && (
          <DrawerButton
            variant="outlined"
            onClick={() => handleClickAndClose('uncomplete')}
            startIcon={<IndeterminateCheckBox />}
          >
            Uncomplete
          </DrawerButton>
        )}
        <RemoveWithConfirmListItem
          hint={removeHint}
          onRemove={() => handleClickAndClose('remove')}
          disabled={readonly}
          button={true}
        />
      </FlexBox>

      <NewDivider />

      <FlexBox>
        {eventTask?.dueDate && (
          <DrawerButton
            fullWidth
            disabled
            variant="outlined"
            startIcon={
              <SportsScore
                sx={{ color: isDateOverdue(eventTask.dueDate) ? theme.palette.danger.main : theme.palette.info.main }}
              />
            }
            sx={{
              justifyContent: 'flex-start',
            }}
          >
            <span
              style={{
                color: isDateOverdue(eventTask.dueDate) ? theme.palette.danger.main : theme.palette.info.main,
                opacity: 0.8,
              }}
            >
              Due: {formatDueScheduledDate(eventTask.dueDate)}
              {/* <SelectDueDateWidget
                  dueDate={eventTask.dueDate}
                  // won't work for Todoist
                  // onChange={(date) => taskOps.setTaskDueDate(task.id, date)}
                /> */}
            </span>
          </DrawerButton>
        )}
        <DrawerButton
          variant="outlined"
          startIcon={<span style={{ marginLeft: '0', fontSize: '0.85em' }}>Start:</span>}
          sx={{
            width: {
              xs: '100%',
              sm: 'calc(50% - 5px)',
            },
            justifyContent: 'flex-start',
          }}
        >
          <DateTimePicker
            date={event.beginDate}
            disabled={readonly}
            onOpen={() =>
              Analytics.event({
                category: 'Event Menu',
                action: 'Clicked DateTime Picker',
              })
            }
            onChange={(newDate) => {
              const diffMinutes = moment(event.endDate).diff(event.beginDate, 'minutes');
              // as time picker is a bit limited component, we make euristic that 00:00 is all-day
              const allDay = newDate.getHours() === 0 && newDate.getMinutes() === 0;
              const beginDate = moment(newDate);
              // when google calendar creates all-day events, the diff is 24h(1440minutes)
              // if moved from all day, we shouldn't use 24h duration - use default 1h instead
              const endDate = moment(newDate).add(!allDay && diffMinutes >= 1440 ? 60 : diffMinutes, 'minutes');
              eventOps.saveEvent({
                ...event,
                allDay,
                beginDate: allDay ? beginDate.format('YYYY-MM-DD') : beginDate.toISOString(),
                endDate: allDay ? endDate.format('YYYY-MM-DD') : endDate.toISOString(),
              });
              Analytics.event({ category: 'Scheduling', action: 'Scheduled Task from DateTime Picker' });
            }}
          />
        </DrawerButton>
        <DrawerButton
          disabled={event.allDay}
          variant="outlined"
          startIcon={<span style={{ marginLeft: '0', fontSize: '0.85em' }}>End:</span>}
          sx={{
            width: {
              xs: '100%',
              sm: 'calc(50% - 5px)',
            },
            justifyContent: 'flex-start',
          }}
        >
          <DateTimePicker
            date={event.allDay ? moment(event.endDate).subtract(1, 'days').format('YYYY-MM-DD') : event.endDate}
            disabled={readonly || event.allDay}
            onOpen={() =>
              Analytics.event({
                category: 'Event Menu',
                action: 'Clicked DateTime Picker - end date',
              })
            }
            onChange={(newDate) => {
              const beginDate = moment(event.beginDate);
              const endDate = moment(newDate);
              if (endDate.isSameOrBefore(beginDate)) {
                return;
              }

              eventOps.saveEvent({
                ...event,
                endDate: endDate.toISOString(),
              });
              Analytics.event({ category: 'Scheduling', action: 'Changed Task EndDate from DateTime Picker' });
            }}
          />
        </DrawerButton>
        {!readonly && (
          <DrawerButton
            variant="outlined"
            startIcon={<ActionDateRange />}
            endIcon={<ExpandMore />}
            ref={rescheduleAnchor}
            onClick={(e) => setRescheduleOptionsAnchorEl(rescheduleOptionsAnchorEl ? null : rescheduleAnchor.current)}
            sx={{
              width: {
                xs: '100%',
                sm: 'calc(50% - 5px)',
              },
              justifyContent: 'flex-start',
            }}
          >
            <span style={{ flexGrow: 1, textAlign: 'left' }}>Quick Reschedule</span>
          </DrawerButton>
        )}
        {attrs.postpone && (
          <Tooltip title="Unscheduled Tasks return to the Task Hub.">
            <DrawerButton
              variant="outlined"
              onClick={() => handleClickAndClose('postpone')}
              startIcon={<EventBusy />}
              sx={{
                width: {
                  xs: '100%',
                  sm: 'calc(50% - 5px)',
                },
                justifyContent: 'flex-start',
              }}
            >
              Unschedule
            </DrawerButton>
          </Tooltip>
        )}
      </FlexBox>
      <NewDivider />
      {!readonly &&
        associatedWithTask && ( // Evnet with Task
          <>
            <FlexBox>
              <Tooltip
                placement="top"
                disableInteractive
                title={
                  cannotSetRecurrence
                    ? 'We only support setting recurrence for tasks synced to Google Calendar. Make sure it is set as your Scheduling Calendar in Calendar Options'
                    : 'Similar to recurring calendar events, scheduled recurring tasks have a fixed time slot and each recurrence is displayed in the calendar timeline. Recurrences can be rescheduled or completed individually, making them ideal for tracking time-based habits. They can also be scheduled in the all-day section.'
                }
              >
                <span>
                  <DrawerButton
                    variant="outlined"
                    startIcon={<LoopIcon />}
                    endIcon={<ExpandMore />}
                    disabled={cannotSetRecurrence}
                    ref={recurrenceAnchor}
                    onClick={(e) =>
                      setRecurrenceOptionsAnchorEl(recurrenceOptionsAnchorEl ? null : recurrenceAnchor.current)
                    }
                  >
                    {event.recurringEventId ? <RecurringEventDetails event={event} /> : 'Set Recurrence'}
                  </DrawerButton>
                </span>
              </Tooltip>
            </FlexBox>
            <NewDivider />
          </>
        )}
      {!!event.recurringEventId &&
        !associatedWithTask && ( // Recurring Event Without Task
          <>
            <FlexBox>
              <DrawerButton
                variant="outlined"
                startIcon={<LoopIcon />}
                // endIcon={<ExternalIcon />}
                disabled
                // onClick={() => { // Does not work google account is not first in the sign in list
                //   window.open(event.htmlLink, '_blank');
                // }}
              >
                <RecurringEventDetails event={event} />
              </DrawerButton>
            </FlexBox>
            <NewDivider />
          </>
        )}

      <FlexBox>
        {event.reminders &&
          event.reminders.overrides &&
          event.reminders.overrides.map((rem, index) => (
            <ReminderItem
              minutes={rem.minutes}
              key={index + '_' + rem.minutes}
              onChange={(min) => setReminder(min, index)}
            />
          ))}
        {maxRemindersCount > 0 && (
          <>
            {(!event.reminders ||
              !event.reminders.overrides ||
              event.reminders.overrides.length < maxRemindersCount) && (
              <ReminderItem minutes={null} withCustom onChange={(min) => setReminder(min, -1)} />
            )}
          </>
        )}
      </FlexBox>

      <Menu
        PaperProps={{
          sx: { minWidth: 180 },
        }}
        anchorEl={rescheduleOptionsAnchorEl}
        open={!!rescheduleOptionsAnchorEl}
        onClose={() => {
          setRescheduleOptionsAnchorEl(null);
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {reschedOps}
      </Menu>

      <Menu
        PaperProps={{
          sx: { minWidth: 180 },
        }}
        anchorEl={recurrenceOptionsAnchorEl}
        open={!!recurrenceOptionsAnchorEl}
        onClose={() => {
          setRecurrenceOptionsAnchorEl(null);
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {recurrenceOps}
      </Menu>

      {eventTask && (
        <>
          <NewDivider />
          <FlexBox>
            <TaskActionPlan task={eventTask} list={eventTaskList.name} note={eventTask.note} key={eventTask.id} />
          </FlexBox>
        </>
      )}
      <NewDivider />
      {attrs.note && (
        <Box style={{ padding: '15px 15px 45px 15px' }}>
          <ListSubheader>
            <span style={{ color: 'rgb(99, 142, 175)', fontSize: '1.1em', fontWeight: 500 }}>
              <EditIcon style={{ margin: '0 5px 5 -5px', verticalAlign: 'middle' }} />
              Note
            </span>
            {attrs.note && (
              <IconButton
                onClick={() => expandDrawer()}
                style={{ position: 'relative', float: 'right', top: '4px', right: '-11px' }}
              >
                {!isExtended ? <OpenInFullIcon /> : <KeyboardTabIcon />}
              </IconButton>
            )}
          </ListSubheader>

          <Note
            key={eventTask.id}
            content={eventTask.note}
            onSave={(content) => saveTaskNote(eventTask, content)}
            focused={() => expandDrawer(true)}
          />
        </Box>
      )}
      {/* </List> */}
    </Box>
  );

  return experimentalMode ? (
    <Drawer
      anchor="right"
      // width={this.state.drawerWidth}
      // openSecondary={true}
      open={open}
      onClose={closeDrawer}
      // docked={false}
      // disableSwipeToOpen={true}
      className={isExtended ? 'drawer isExtended' : 'drawer'}
      sx={{
        [`& .MuiDrawer-paper`]: {
          width: (theme) => (isExtended ? theme.eventContextMenu.widthExpanded : theme.eventContextMenu.width),
          margin: '5px',
          height: 'calc(100% - 10px)',
          borderRadius: '28px',
          overflow: 'hidden',
          '@media (max-width: 700px)': {
            width: '80vw',
          },
        },
      }}
      // containerStyle={{ maxWidth: "100vw", overflowX: "hidden" }}
    >
      {content}
    </Drawer>
  ) : (
    <Dialog open fullWidth onClose={closeDrawer}>
      {/* <DialogTitle>{event.title}</DialogTitle> */}
      <DialogContent>{content}</DialogContent>
    </Dialog>
  );
};

const CustomReminderDialog = ({ onCloseWith }) => {
  const [val, setVal] = useState('');
  const [mult, setMult] = useState(1);
  console.log({ val, mult });

  const handleSubmit = (e) => {
    e.preventDefault();
    onCloseWith(+val * mult);
  };

  return (
    <Dialog open onClose={() => onCloseWith()}>
      <DialogTitle>Add Custom Reminder</DialogTitle>
      <DialogContent>
        <form
          // id="custom-reminder-field"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <Box
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
              padding: '20px',
            }}
          >
            <div>
              <TextField required autoFocus type="number" variant="standard" onChange={(e) => setVal(e.target.value)} />
              <TextField select variant="standard" value={mult} onChange={(e) => setMult(+e.target.value || 1)}>
                <MenuItem value={1}>Minutes before</MenuItem>
                <MenuItem value={60}>Hours before</MenuItem>
                <MenuItem value={1440}>Days before</MenuItem>
                <MenuItem value={10080}>Weeks before</MenuItem>
              </TextField>
            </div>
            <Stack spacing={2} direction="row" justifyContent="flex-end">
              <Button variant="outlined" onClick={() => onCloseWith()}>
                Cancel
              </Button>
              <Button variant="contained" type="submit">
                Create Reminder
              </Button>
            </Stack>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};
